/**
 * services/uploader
 */

import { trim, identity } from 'lodash';
import Promise from 'bluebird';

import oss from './oss';
import textToBlob from '../utils/text-to-blob';
import dataURLToBlob from '../utils/dataurl-to-blob';
import getImageSize from '../utils/get-image-size';


class Uploader {

}

Object.assign(Uploader, {
    uid: 0,
    typeExtMap: {
        jpeg: 'jpg',
        'svg+xml': 'svg'
    },
    getExtByType(type) {
        const subType = trim(type.split('/')[1]);

        return this.typeExtMap[subType] || subType;
    },
    processes: {
        blob: identity,
        base64: dataURLToBlob,
        text(text, options) {
            const mime = options && options.mime;

            return textToBlob(text, mime);
        }
    },
    upload(data, options) {
        // options
        if (typeof options === 'string') {
            options = {
                key: options
            };
        }

        options = Object.assign({
            dataType: 'blob'
        }, options);

        return Promise.try(() => {
            const dataType = options.dataType;
            const dataProcess = this.processes[dataType];

            if (!dataProcess) {
                throw new Error('No upload process defined: ' + dataType);
            }

            return dataProcess(data.rawFile || data, options);
        })
            .then(data => {
                return oss.upload(data, options.key, options);
            });
    },
    uploadImage(file, options = {}) {
        return Promise.try(() => {
            const url = URL.createObjectURL(file.rawFile || file);
            return getImageSize(url)
                .then(size => {
                    URL.revokeObjectURL(url);

                    return size;
                });
        })
            .then(size => {
                return this.upload(file, options)
                    .then(ret => {
                        size.url = ret.url;

                        return size;
                    });
            });
    }
});

export default Uploader;
