<style lang="less">
.ps-layout {
    min-width: 1200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    img, a {
        -webkit-user-drag: none;
    }
}
</style>
<template>
    <div class="ps-layout">
        <V2UupoopNav />
        <router-view></router-view>
        <Footer />
    </div>
</template>

<script>
import { get } from "lodash";
import V2UupoopNav from "./v2-uupoop-nav";
import Footer from './footer'
import {userSession} from '../services/user-session'

export default {
    name: "root",
    components: {
        V2UupoopNav,
        Footer
    },
    created() {
        userSession.init()
    }
};
</script>
