<template>
    <div class="gaoding-uupoop-root-v2-ps-simplify">
        <div class="wrapper">
            <article>
                <mark>在线PS精简版</mark>
                <h5 class="title">简单好用的图片编辑工具</h5>
                <section class="function-detail">
                    <div v-for="(item, index) in functionDetailList" :key="item.id" :class="{'flex-end': index > 2}">
                        <img :src="item.icon" :alt="item.title" />
                        <span>{{item.title}}</span>
                    </div>
                </section>
                <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: '立即作图'
                }">
                    <AButton
                        @click="handleClick"
                        type="primary"
                        class="to-pic"
                    >
                        立即作图
                    </AButton>
                </TrackButton>
            </article>
            <Carousel/>
        </div>
    </div>
</template>

<script>
import Carousel from './carousel.vue';
import { functionDetailList } from './constant.js';
import { Button as AButton } from '@gaoding/gd-antd';
import { linkTo } from '../../utils/query';
import { GAODING_UUPOOP_ENV } from "../../services/env"
import TrackButton from "../../trackers/track-button"

export default {
    name: 'uupoop-root-v2-ps-simplify',
    components: {
        Carousel,
        AButton,
        TrackButton,
    },
    data() {
        return {
            functionDetailList,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl
        }
    },
    methods: {
        handleClick() {
            linkTo(`${this.gaodingUrl}/editor/image`, '_blank');
        }
    }
}
</script>
<style lang="less">
.gaoding-uupoop-root-v2-ps-simplify {
    @layout-width: 1200px;
    @scale: 0.95;
    @primary-color: #2254F4; // 定义主色调变量
    @text-color: #222529; // 定义文本颜色变量

    .wrapper {
        width: @layout-width;
        min-width: @layout-width;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1440px) {
            width: @layout-width * @scale;
            min-width: @layout-width * @scale;
        }
    }
    .to-pic {
        width: 200px;
        height: 52px;
        background: @primary-color;
        border-color: @primary-color;
        font-size: 16px;
        line-height: 52px;
        color: #FFFFFF;
        display: inline-block;
        text-decoration: none;
        text-align: center;
    }
    mark {
        background: #2a2b31;
        border-radius: 90px;
        height: 28px;
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        color: rgba(255, 255, 255, 0.8);
        padding: 3px 15px;
        display: inline-block;
    }
    .title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: @text-color;
        margin: 24px 0px;
    }
    .function-detail {
        width: 336px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 40px;
        div {
            flex-basis: 88px;
            height: 22px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 11px;
            }
            span {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: @text-color;
            }
        }
        .flex-end {
            align-self: flex-end;
        }
    }
}
</style>
