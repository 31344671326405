export const eventNames = {
  /**
   *
   * @param {string} name login | register
   * @returns
   */
  appSuccess(name) {
    return `app.${name}.success`;
  },
  appError(name) {
    return `app.${name}.error`;
  },

  guestLogin: 'guest.login',

  // 登出
  logout: 'logout',

  CAHNGE_USER: 'user.change',

  // 埋点事件
  trakcer: 'trakcer',
  // 上报无垠埋点
  trackerWind: 'trackerWind',
  editSessionStart: 'editSessionStart',
  editSessionEnd: 'editSessionEnd',

  appQuestionaireCheck: 'app.questionnaire.check',
  scrollToTop: 'scrollToTop',

  editorReady: 'editorReady',

  changeVIP: 'changeVIP',
};

export class Event {
  constructor() {
    this.events = {};
  }

  events;

  eventNames = eventNames;

  add(name, fn) {
    const hooks = this.get(name);
    hooks.push(fn);
    this.events[name] = hooks;

    return () => this.off(name, fn);
  }

  on(name, fn) {
    return this.add(name, fn);
  }

  off(name, fn) {
    const hooks = this.get(name);
    if (hooks) {
      const index = hooks.findIndex((f) => f !== fn);
      hooks.splice(index, 1);
    }
  }

  get(name) {
    return this.events[name] || [];
  }

  invoke(name, ...args) {
    for (const hook of this.get(name)) {
      hook(...args);
    }
  }

  emit(name, ...args) {
    this.invoke(name, ...args);
  }

  async asyncInvoke(name, ...args) {
    for (const hook of this.get(name)) {
      await hook(...args);
    }
  }

  async asyncInvokeAll(name, ...args) {
    await Promise.all(this.get(name).map((hook) => hook(...args)));
  }

  clear() {
    this.events = {};
  }
}

export const event = new Event();

export default event;
