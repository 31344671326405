<template>
    <V2OtherNav type="uupoop" :session="session" class="uupoop-nav" :uploadFunc="uploadFunc" @expose="onTrackerExpose" @click="onTrackerClick">
        <template v-slot:logo>
            <a href="/" target="_blank">
                <img src="../../assets/uupoop.svg" />
            </a>
        </template>
        <button class="version-ctrl-btn" @click="onClick">PS专业版</button>
    </V2OtherNav>
</template>

<script>
import { EditorNav } from '@gaoding/gd-materials';
import { Dialog, Icon } from '@gaoding/gdui';
import { UUPOOP_MENUS } from '@gaoding/gd-materials/lib/editor-nav/other-nav/config';
import { userSession } from '../../services/user-session';
import { buttonTracker } from '@/trackers';
import Uploader from '../../services/uploader';

Icon.use('https://st-gdx.dancf.com/assets/20200310-145233-950a.js');

UUPOOP_MENUS.splice(0, 1);

UUPOOP_MENUS.push({
    name: '设计素材',
    url: 'https://www.gaoding.com/utms/7655a6a5d383f19a2a2ab804a6f9bb63',
    bgColor: '#2254F4',
    color: 'white',
});

const { V2OtherNav } = EditorNav;

export default {
    name: 'uupoop-nav',
    components: {
        V2OtherNav,
    },
    computed: {
        session() {
            return userSession;
        },
    },
    methods: {
        async uploadFunc(file) {
            const res = await Uploader.upload(file);
            return res.url;
        },
        async onClick() {
            const isEditor = this.$route.name === 'editor-page';
            let isSuccess = true;
            this.onTrackerClick({ 
                module_nm: '导航栏',
                button_nm:  '返回专业版',
            });
            if (isEditor) {
                isSuccess = await Dialog.open({
                    centered: true,
                    title: '是否切换至PS专业版',
                    content: '将清空本次编辑结果，切换至PS编辑器',
                    okText: '确 定',
                    cancelText: '取 消',
                });
            }
            if (!isSuccess) return;
            const url = 'https://ps.gaoding.com/#/';
            location.href = url;
             
        },
        onTrackerExpose(data) {
            buttonTracker.expose(data);
        },
        onTrackerClick(data) {
            buttonTracker.click(data);
        },
    },
};
</script>
<style lang="less">
.uupoop-nav {
    white-space: nowrap;
    .main-nav {
        min-width: 1280px !important;
    }
    .gm-v3-header-user__menu {
        text-decoration: none !important;
    }
    .other-nav-left {
        position: relative;
        padding-right: 100px;
        .version-ctrl-btn {
            width: 80px;
            height: 32px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
            border-radius: 16px;
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #fff;
            cursor: pointer;
            outline: none;
            background: rgba(255, 255, 255, 0.1);

            &:hover {
                background: rgba(255, 255, 255, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.5);
            }
        }
    }

    .item .img {
        overflow: hidden;
    }

    .black-content-content-block .right .text .link>i {
        margin-top: 4px;
    }

    .black-content-content-block .link-btn > i {
        margin-top: 2px;
    }
}
</style>
