<template>
    <svg
        role="img"
        aria-label="ai-stroke"
        class="gd_design_icon gd_design_icon-ai-stroke"
        focusable="false"
        data-icon="ai-stroke"
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.56881 3H8.7779V4.36126H5.56881C5.06761 4.36126 4.6613 4.76756 4.6613 5.26876V7.86568H3.30005V5.26876C3.30005 4.01576 4.31581 3 5.56881 3ZM5.56881 19.0387H8.7779V20.4H5.56881C4.31581 20.4 3.30005 19.3842 3.30005 18.1312V15.5343H4.6613L4.6613 18.1312C4.6613 18.6324 5.06761 19.0387 5.56881 19.0387ZM15.2223 19.0387V20.4H18.4313C19.6843 20.4 20.7 19.3842 20.7 18.1312V15.5343H19.3388V18.1312C19.3388 18.6324 18.9325 19.0387 18.4313 19.0387H15.2223ZM15.2223 3V4.36126H18.4313C18.9325 4.36126 19.3388 4.76756 19.3388 5.26876V7.86568H20.7V5.26876C20.7 4.01576 19.6843 3 18.4313 3H15.2223ZM6.89534 15.1031L9.74314 7.65501H11.1305L13.9783 15.1031H12.6744L11.9964 13.2359H8.87733L8.19928 15.1031H6.89534ZM10.4212 8.96938L9.24243 12.2344H11.6312L10.4629 8.96938H10.4212ZM14.7055 15.1031V7.65501H15.926V15.1031H14.7055Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
import './index.less';

export default {
    name: 'IconAiStroke',
};
</script>
