import { createWindTrack } from '@gaodingx/shared/lib/lib/tracker';

export const resourceTracker = createWindTrack({
    /**
     * 资源曝光
     * 文档地址：https://doc.huanleguang.com/pages/viewpage.action?pageId=174022277
     *
     */
    expose(data: {
        click_source?: string;
        resource_type: string;
        resource_id?: string;
        resource_title: string;
    }) {
        return {
            event_id: 35001,
            event: 'resource_loc_expose',
            click_source: data.click_source,
            resource_type: data.resource_type,
            resource_id: data.resource_id,
            resource_title: data.resource_title,
            ...data,
        };
    },
    click(data: {
        click_source?: string;
        resource_type?: string;
        resource_id?: string;
        resource_title: string;
        click_type?: string
    }) {
        return {
            event_id: 35002,
            event: 'resource_loc_click',
            click_source: data.click_source,
            resource_type: data.resource_type,
            resource_id: data.resource_id,
            resource_title: data.resource_title,
            click_type: data.click_type,
            ...data,
        };
    },
});
