<template>
    <div class="gaoding-uupoop-header">
        <div>
            <img @click="linkTo(`${gaodingUrl}`)" class="gaoding-uupoop-header-logo" src="https://cdn.dancf.com/fe-assets/20230614/logo/logo-monochrome.svg" alt="">
        </div>
        <div class="gaoding-uupoop-header-right">
            <img @click="linkTo(`${gaodingUrl}/uupoop?login=1`)" class="vip-btn" src="https://st0.dancf.com/market-operations/side/content-assembly/1657093072378.png" alt="">
            <Button class="btn-link"
                size="small"
                target="__blank"
                type="link"
                @click="linkTo('http://www.gaoding.com/qiye?utm_source=gd','_blank')"
            >
                <IconAddress style="color:#2254f4"/>
                企业服务
            </Button>
            <div class="gaoding-uupoop-header-icon-wrap" @click="linkTo(`${gaodingUrl}/download?channelCode=web_navi`)">
                <IconDownloadToPhone />
            </div>
            <Button class="btn" type="primary" size="small" @click="linkTo(`${gaodingUrl}/uupoop?login=1`)">
                <span>登录/注册</span>
            </Button>
        </div>
    </div>
</template>
<script>
import { Button } from "@gaoding/gd-antd"
import { IconDownloadToPhone, IconAddress } from "../../icons"
import { GAODING_UUPOOP_ENV } from "../../services/env"
import { linkTo } from  "../../utils/query"

export default ({
    components: {
        Button,
        IconDownloadToPhone,
        IconAddress,
    },
    data(){
        return {
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl,
        }
    },
    methods:{
        linkTo,
    }
})
</script>

<style lang="less">

.gaoding-uupoop-header {
    position: relative;
    width: 100%;
    height: 57px;
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.08);
    display: flex;
    align-items: center;
    padding: 0 40px 0 28px;
    justify-content: space-between;
    box-sizing: border-box;
    .gdesign-icon {
        width:20px;
        height: 20px;;
    }
    .gaoding-uupoop-header-icon-wrap {
        width: 20px;
        height: 20px;
        cursor: pointer;
        display: inline-block;
    }
    .gaoding-uupoop-header-logo {
        width: 64px;
        height: 28px;
        object-fit: contain;
        cursor: pointer;
    }

    .gaoding-uupoop-header-right {
        display: flex;
        align-items: center;
        .vip-btn {
            width: 125px;
            height: 33px;
            cursor: pointer;
            margin-right: 8px;
        }
        .btn-link {
            color: #2254f4;
            background: #f0f6ff;
            border-color: #f0f6ff;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-right: 16px;
            padding: 0px 11px;
            &:hover {
                background-color: #c7dbff;
                border-color: #c7dbff;
            }
        }
        .btn {
            padding: 0 12px;
            margin-right: 0px;
            margin-left: 12px;
            font-size: 14px;
            font-weight: 400;
            span {
                display:inline-block;
                width: 63px;

            }
        }

    }
}


</style>
