<template>
    <div class="gaoding-uupoop-menu" >
        <div v-for="item in menus" :key="item.title" class="gaoding-uupoop-menu-item">
            <div class="gaoding-uupoop-menu-item-title"  v-if="item.title">
                {{ item.title }}
            </div>
            <div
                class="gaoding-uupoop-menu-item-child"
                v-for="child in item.children"
                :key="child.title"
            >
                <div
                    v-if="child.icon"
                    class="gaoding-uupoop-menu-item-child-icon"
                >
                    <component :is="child.icon" />
                </div>
                <a class="gaoding-uupoop-menu-item-child-title" :href="`${gaodingUrl}${child.href}`">
                    <span> {{ child.title}} </span>
                    <img v-if="child.limitTimeIcon" :src="child.limitTimeIcon" alt="" >
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { menus } from "./constant"
import {
    IconOpenFile,
    IconRecent,
    IconUserCircle,
    IconAiStroke,
    IconCompass,
    IconTemplate,
    IconIdPhoto,
    IconSmartDesign,
    IconPhotoEditor,
    IconCutout,
    IconTool,
} from '../../icons';
import { GAODING_UUPOOP_ENV } from "../../services/env"

export default ({
    components:{
        IconOpenFile,
        IconRecent,
        IconUserCircle,
        IconAiStroke,
        IconCompass,
        IconTemplate,
        IconIdPhoto,
        IconSmartDesign,
        IconPhotoEditor,
        IconCutout,
        IconTool,
    },
    data() {
        return {
            menus,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl,
        }
    },
})
</script>

<style lang='less'>
.gdesign-icon {
    width: 1em;
    height: 1em;
    overflow: hidden;
    font-size: 20px;
    vertical-align: -.15em;
    color: #000;
    margin-right: 10px;
}

.gaoding-uupoop-menu {
    width: 232px;
    height: 100%;
    padding: 19px 0 20px 16px;
}
.gaoding-uupoop-menu-item {

}

.gaoding-uupoop-menu-item-title {
    padding: 0 12px;
    margin-top: 20px;
    margin-bottom: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #9da3ac;
    display: flex;
    align-items: center;
    -webkit-font-smoothing: antialiased;
}

.gaoding-uupoop-menu-item-child {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    height: 40px;
    padding: 0 12px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: flex-start;
    height: 40px;
    padding: 0 12px;
    border-radius: 8px;
    cursor: pointer;
    width: 216px;
    height: 40px;
    &:hover {
        background: #f6f7f9;
    }
}

.gaoding-uupoop-menu-item-child-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.gaoding-uupoop-menu-item-child-title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    color: #000;
    height: 40px;
    span {
        display: flex;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        height: 20px;
        position: relative;
    }
    img {
        margin-left: 8px;
    }
}
</style>
