<style lang="less">
.uupoop-root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    img, a {
        -webkit-user-drag: none;
    }
}
</style>
<template>
    <div :class="{ 'uupoop-root': !isOldVersion }">
        <UupoopNav />
        <router-view></router-view>
        <template v-if="!isOldVersion">
            <Footer />
        </template>
    </div>
</template>

<script>
import { get } from "lodash";
import UupoopNav from "./uupoop-nav";
import Footer from './footer'
import {userSession} from '../services/user-session'

export default {
    name: "root",
    components: {
        UupoopNav,
        Footer
    },
    computed: {
      isOldVersion() {
        return this.$route.name === 'old-page'
      }
    },
    created() {
        localStorage.clear();
        userSession.isLogin && userSession.init()
    }
};
</script>
