<template>
    <OtherNav type="uupoop" :session="session" class="uupoop-nav" @click="onNavTracker">
        <template v-slot:logo>
            <a href="/" target="_blank">
                <img src="../../assets/uupoop.svg" />
            </a>
        </template>
        <button class="version-ctrl-btn" @click="onClick">
            {{ isOldVersion ? "体验新版" : "返回旧版" }}
        </button>
    </OtherNav>
</template>

<script>
import EditorNav from "@gaoding/gd-materials/lib/editor-nav";
import { Dialog, Icon } from "@gaoding/gdui";
import { UUPOOP_MENUS } from "@gaoding/gd-materials/lib/editor-nav/other-nav/config";
import { userSession } from "../../services/user-session";
import { resourceTracker } from '@/trackers';

Icon.use("https://st-gdx.dancf.com/assets/20200310-145233-950a.js");

UUPOOP_MENUS.splice(0, 1);

UUPOOP_MENUS.push({
    name: "设计素材",
    url: "https://www.gaoding.com/utms/7655a6a5d383f19a2a2ab804a6f9bb63",
    bgColor: "#2254F4",
    color: "white",
});

const targetItems = UUPOOP_MENUS.find((item) => item.name === '简易版')
targetItems.name = '图片编辑器';
targetItems.url = "https://www.gaoding.com/image"
targetItems.width = '110px'
targetItems.aTarget = '_blank';
targetItems.childs = [
    {
        name: '批量裁剪',
        url: 'https://www.gaoding.com/image',
    },
    {
        name: '图片美化',
        url: 'https://www.gaoding.com/image',
    },
    {
        name: '消除笔',
        url: 'https://www.gaoding.com/image',
    },
]



const { OtherNav } = EditorNav;

export default {
    name: "uupoop-nav",
    components: {
        OtherNav,
    },
    computed: {
        session() {
            return userSession;
        },
        isOldVersion() {
            return this.$route.name === "old-page";
        },
    },
    methods: {
        async onClick() {
            const { isOldVersion } = this;
            const isEditor = this.$route.name === "editor-page" || isOldVersion;
            let isSuccess = true;
            this.onNavTracker({ click_type: isOldVersion ? "体验新版" : "返回旧版"});
            if (isEditor) {
                isSuccess = await Dialog.open({
                    centered: true,
                    title: isOldVersion
                        ? "是否切换至新版编辑器？"
                        : "是否切换至旧版编辑器？",
                    content: isOldVersion
                        ? "将清空本次编辑结果，切换至新版编辑器"
                        : "将清空本次编辑结果，切换至旧版编辑器",
                    okText: "确 定",
                    cancelText: "取 消",
                });
            }
            if (!isSuccess) return;
            const url = isOldVersion ? "/" : "/#/old";
            location.href = url;
            if (!isOldVersion) {
                setTimeout(() => {
                    location.reload();
                });
            }
        },
        onNavTracker(data) {
            if(!data) {
                return;
            }
            const { click_type } = data;
            resourceTracker.click({
                click_source: 'uupoop',
                resource_type: '顶部导航栏',
                resource_title: click_type
            });
        }
    },
};
</script>
<style lang="less">
.uupoop-nav {
    white-space: nowrap;
    .main-nav {
        min-width: 1280px !important;
    }
    .gm-v3-header-user__menu {
        text-decoration: none !important;
    }
    .other-nav-left {
        position: relative;
        padding-right: 100px;
        .version-ctrl-btn {
            width: 80px;
            height: 32px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid rgba(255, 255, 255, 0.3);
            box-sizing: border-box;
            border-radius: 16px;
            font-family: PingFang SC;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: #cfcfcf;
            cursor: pointer;
            outline: none;
            background: #111111;

            &:hover {
                background: #323232;
            }
        }
    }
}
</style>
