import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store';
import './services/user-session';
import './services/init';
import { initTracker } from './tracker'; 


import './style/index.less';
import '@gaoding/gd-antd/dist/antd.css';
import 'normalize.css';


Vue.config.productionTip = false;


const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');


initTracker(app)