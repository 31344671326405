<template>
    <div class="root-v2-btn">
        <TrackButton
            :trackData="{
                page_name: 'UUPOOP页',
                module_name: '创作类型选择',
                button_name: primaryText
            }"
        >
            <AButton class="professional" target="_blank" @click="$emit('primary')">{{ primaryText }}</AButton>
        </TrackButton>
        <TrackButton
            :trackData="{
                page_name: 'UUPOOP页',
                module_name: '创作类型选择',
                button_name: secondText
            }"
        >
            <AButton class="simplify" target="_blank" @click="$emit('second')" :href="secondLink">{{ secondText }}</AButton>
        </TrackButton>
    </div>
</template>

<script>
import { Button } from '@gaoding/gd-antd';
import TrackButton from '../../trackers/track-button';

export default {
    name: 'root-v2-btn',
    components: {
        AButton: Button,
        TrackButton
    },
    props: {
        primaryText: String,
        secondText: String,
        primaryLink: String,
        secondLink: String
    }
}
</script>

<style lang="less">
.root-v2-btn {
    text-align: center;
    .gda-btn {
        width: 200px;
        height: 52px;
        display: inline-block;
        line-height: 52px;
        text-decoration: none;
    }
    .professional {
        margin-right: 20px;
        background: #2254f4;
        color: #fff;
        font-size: 16px;
        border-color: #2254f4;
        &:hover {
            background: #4d7cff;
            color: #fff;
            border-color: #4d7cff;
        }
        &:active {
            background: #1f4de0;
            color: #fff;
            border-color: #1f4de0;
        }
    }
    .simplify {
        background: #2a2b31;
        border-color: #2a2b31;
        font-size: 16px;
        color: #fff;
        &:hover {
            background: #42434b;
            border-color: #42434b;
            color: #fff;
        }
        &:active {
            background: #27282f;
            border-color: #27282f;
            color: #fff;
        }
    }
}
</style>
