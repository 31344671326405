const ENV_HOST_MAP = {
    default: "stage",
    "local.uupoop.com": 'dev',
    "www.dev.uupoop.com": 'dev',
    "stage.uupoop.com": 'stage',
    "www.uupoop.com": "prod",
};

const { host } = window.location;


export const env = ENV_HOST_MAP[host] || ENV_HOST_MAP.default;

const ENV_MAP = {
    // dev: {
    //     env: 'dev',
    //     assetsBaseURL: 'https://st-gdx.dancf.com/',
    //     assetsPrefix: "gaodingx-dev/",
    //     appUmsUrl: 'http://ums.dev.gaoding.com',
    //     gaodingUrl: 'http://www.dev.gaoding.com',
    //     psUrl: 'https://ps.gaoding.com/dev/index.html'
    // },
    dev: {
        env: 'fat',
        assetsBaseURL: 'https://st-gdx.dancf.com/',
        assetsPrefix: "gaodingx-dev/",
        appUmsUrl: 'http://ums.dev.gaoding.com',
        gaodingUrl: 'https://fat.gaoding.com',
        psUrl: 'https://ps.gaoding.com/dev/index.html'
    },
    stage: {
        env: 'stage',
        assetsBaseURL: 'https://st-gdx.dancf.com/',
        assetsPrefix: "gaodingx-stage/",
        appUmsUrl: 'https://ums.stage.gaoding.com',
        gaodingUrl: 'https://stage.gaoding.com',
        psUrl: 'https://ps.gaoding.com/release/index.html'
    },
    prod: {
        env: 'prod',
        assetsBaseURL: 'https://st-gdx.dancf.com/',
        assetsPrefix: "gaodingx/",
        appUmsUrl: 'https://ums.gaoding.com',
        gaodingUrl: 'https://www.gaoding.com',
        psUrl: 'https://ps.gaoding.com/'
    }
}

const GAODING_UUPOOP_ENV_MAP = {
    dev: {
        env: 'dev',
        gaodingUrl: 'https://fat.gaoding.com',
    },
    stage: {
        env: 'stage',
        gaodingUrl: 'https://stage.gaoding.com',
    },
    prod: {
        env: 'prod',
        gaodingUrl: 'https://www.gaoding.com',
    }
}
export const GAODING_UUPOOP_ENV = GAODING_UUPOOP_ENV_MAP[env];

export default ENV_MAP[env]
