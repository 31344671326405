<template>
    <div class="gaoding-uupoop-material">
        <div class="title">
            <h1>海量素材，无限精彩</h1>
            <p>一站式全场景素材供应人人皆可创作</p>
        </div>
        <div class="templet-content">
            <TrackButton :trackData="{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: templetMap.image.title
            }">
                <div
                    class="templet-content-item"
                    :style="{ 'background-image': `url(${templetMap.image.backgroundImage})` }"
                    @click="linkTo(templetMap.image.path)"
                >
                    <div class="templet-content-item-header">
                        <div class="templet-content-item-header-title">
                            {{ templetMap.image.title }}
                        </div>
                        <div class="templet-content-item-header-icon"><IconArrowForward /></div>
                    </div>
                </div>
            </TrackButton>
            <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: templetMap.h5.title
            }">
                <div
                    class="templet-content-item"
                    :style="{ 'background-image': `url(${templetMap.h5.backgroundImage})` }"
                    @click="linkTo(templetMap.h5.path)"
                >
                    <div class="templet-content-item-header">
                        <div class="templet-content-item-header-title">
                            {{ templetMap.h5.title }}
                        </div>
                        <div class="templet-content-item-header-icon"><IconArrowForward /></div>
                    </div>
                </div>
            </TrackButton>
            <div class="templet-content-item-wrap">
                <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: templetMap.ppt.title
                }">
                <div
                    :class="['templet-content-item', 'templet-content-item-small']"
                    :style="{ 'background-image': `url(${templetMap.ppt.backgroundImage})` }"
                    @click="linkTo(templetMap.ppt.path)"
                >
                    <div class="templet-content-item-header">
                        <div class="templet-content-item-header-title">
                            {{ templetMap.ppt.title }}
                        </div>
                        <div class="templet-content-item-header-icon">
                            <IconArrowForward />
                        </div>
                    </div>
                </div>
                </TrackButton>
                <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: templetMap.video.title
                }">
                <div
                    :class="['templet-content-item', 'templet-content-item-small']"
                    :style="{ 'background-image': `url(${templetMap.video.backgroundImage})` }"
                    @click="linkTo(templetMap.video.path)"
                >
                    <div class="templet-content-item-header">
                        <div class="templet-content-item-header-title">
                            {{ templetMap.video.title }}
                        </div>
                        <div class="templet-content-item-header-icon">
                            <IconArrowForward />
                        </div>
                    </div>
                </div>
                </TrackButton>
            </div>
        </div>
        <div class="divide"></div>
        <div class="title">
            <h1>稿定Al，一键灵感到创作</h1>
            <p>让设计更简单，无论身在何处</p>
        </div>
        <div class="ai-content">
            <TrackButton
                v-for="item in AiList"
                :key="item.id"
                :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: item.title
                }">
            <div
                class="ai-content-item"
                :style="{
                    'background-color': item.backgroundColor,
                }"
                @click="linkTo(item.path)"
            >
                <div class="ai-content-item-header">
                    <div class="ai-content-item-header-title">
                        {{ item.title }}
                    </div>
                    <div class="ai-content-item-header-icon">
                        <IconArrowForward />
                    </div>
                </div>
                <div class="ai-content-item-content">
                    {{ item.desc }}
                </div>
                <img :src="item.image" :alt="item.title" />
            </div>
            </TrackButton>
        </div>
    </div>
</template>

<script>

import { IconArrowForward } from '../../icons';
import { AiMap, templetMap } from './constant';
import { linkTo } from  "../../utils/query"
import { GAODING_UUPOOP_ENV } from "../../services/env"
import TrackButton from "../../trackers/track-button"

const AiList = Object.values(AiMap).sort((a, b) => a.order - b.order);
export default ({
    components: {
        IconArrowForward,
        TrackButton,
    },
    data() {
        return {
            templetMap,
            AiMap,
            AiList,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl
        };
    },
    methods:{
        gbem(str){
            return  'gaoding-uupoop-material' + str
        },
        linkTo(path){
             linkTo(`${this.gaodingUrl}${path}`, '_blank')
        }
    }
});
</script>
<style lang="less">
.gaoding-uupoop-material {
    @layout-width: 1200px;
    @scale: 0.95;

    width: @layout-width;
    margin: auto;

    @media screen and (max-width: 1440px) {
        width: calc(@layout-width * @scale);
    }
        .title {
            width: 100%;
            margin-bottom: 40px;
            font-family: 'PingFang SC';
            text-align: center;

        h1 {
            height: 40px;
            font-family: 'PingFang SC';
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            color: #222529;
            text-align: center;
        }

        p {
            height: 22px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: #4c535c;
        }
    }

    .templet-content {
        display: flex;
        justify-content: space-between;

        .templet-content-item-wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .templet-content-item {
            width: 384px;
            height: 500px;
            padding: 36px 32px;
            background-color: #f6f7f9;
            background-position: center;
            background-size: 100% 100%;
            border-radius: 12px;
            cursor: pointer;
            transition: 0.5s;

            @media (max-width: 1440px) {
                width: calc(384px * @scale);
                height: calc(500px * @scale);
            }

            &:hover {
                background-size: 103% 103%;
                div {
                    left: 0%;
                    opacity: 1;
                }
            }

            .templet-content-item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 34px;
            }

            .templet-content-item-header-title {
                    height: 34px;
                    overflow: hidden;
                    font-family: 'PingFang SC';
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 34px;
                    color: #222529;
                }

            .templet-content-item-header-icon {
                position: relative;
                left: -20px;
                opacity: 0;
                transition: 0.5s;
                svg {
                    width: 37px;
                    height: 32px;
                }
            }
        }

        .templet-content-item-small {
            height: 238px;

            @media (max-width: 1440px) {
                height: calc(238px * @scale);
            }
        }
    }

    .divide {
        width: 100%;
        height: 128px;
    }

    .ai-content {
        display: flex;
        justify-content: space-between;

        .ai-content-item {
            position: relative;
            width: 285px;
            height: 420px;
            padding: 36px 32px;
            overflow: hidden;
            background-size: 100% 100%;
            border-radius: 12px;
            cursor: pointer;

            @media (max-width: 1440px) {
                width: calc(285px * @scale);
                height: calc(420px * @scale);
            }

            &:hover {
                img {
                    transform: scale(1.03);
                }

                div {
                    left: 0%;
                    opacity: 1;
                }
            }

            .ai-content-item-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 34px;
                color: #fff;
            }
            .ai-content-item-header-title {
                height: 34px;
                overflow: hidden;
                font-family: 'PingFang SC';
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 34px;
            }

            .ai-content-item-header-icon {
                position: relative;
                left: -20px;
                opacity: 0;
                transition: 0.5s;
                svg {
                    width: 37px;
                    height: 32px;
                }

            }

            .ai-content-item-content {
                margin-top: 8.22px;
                font-family: 'PingFang SC';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                color: #fff;
            }

            img {
                position: absolute;
                top: 153px;
                left: 0;
                width: 100%;
                transition: 0.5s;
            }
        }
    }
}


</style>
