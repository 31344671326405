<template>
    <span ref="trackButton">
        <slot></slot> <!-- 插槽允许外部内容被插入到这个组件 -->
    </span>
</template>

<script>
import { buttonTracker } from "./button"; // 导入埋点工具

export default {
    props: {
        trackData: {
            type: Object,
            default: () => ({}) // 默认的追踪数据
        }
    },
    data() {
        return {
            observer: null, // 用于存储 IntersectionObserver 实例
            childNode: null, // 存储子节点引用，用于绑定点击事件
        }
    },
    methods: {
        trackClickEvent() {
            buttonTracker.click({
                ...this.trackData,
                subject_name: "按钮",
                project_name: undefined,
                browser_language: undefined,
            })
        },
        setupObserver() {
            const target = this.$refs.trackButton;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.handleExposure(entry.target);
                    }
                });
            }, { threshold: [0.8] });

            observer.observe(target);
            this.observer = observer;
        },
        handleExposure(target) {
            buttonTracker.expose({
                ...this.trackData,
                subject_name: "按钮",
                project_name: undefined,
                browser_language: undefined,
            });
            this.observer.unobserve(target);
        },
        bindClickEvent() {
            const childNode = this.$refs.trackButton.childNodes[0];
            if (!childNode) return;
            this.childNode = childNode;
            childNode.addEventListener('click', this.trackClickEvent, true);
        }
    },
    mounted() {
        this.setupObserver();
        this.bindClickEvent();
    },
    beforeDestroy() {
        if (this.observer && this.$refs.trackButton) {
            this.observer.unobserve(this.$refs.trackButton);
            this.observer = null;
        }
        if (this.childNode) {
            this.childNode.removeEventListener('click', this.trackClick);
        }
    }
}
</script>
