<template>
    <div class="gaoding-uupoop-ps-draw">
        <div class="gaoding-uupoop-ps-draw-content">
            <div class="gaoding-uupoop-ps-draw-header">
                <img
                    src="https://cdn.dancf.com/fe-assets/20230614/logo/logo-monochrome.svg"
                    alt="PS立即作图"
                />
                <div class="gaoding-uupoop-ps-draw-divider"></div>
                <div class="gaoding-uupoop-ps-draw-desc">在线PS专业版</div>
            </div>
            <p class="gaoding-uupoop-ps-draw-title">让设计更简单，无论身在何处</p>
            <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'在线PS专业版banner',
                    button_name: '立即作图'
            }">
                <Button href="https://ps.gaoding.com" target="_blank" class="gaoding-uupoop-ps-draw-button">
                    立即作图
                </Button>
            </TrackButton>
        </div>
    </div>
</template>
<script>

import { Button } from '@gaoding/gd-antd';
import TrackButton from "../../trackers/track-button"

export default {
    components: {
        Button,
        TrackButton,
    }
};
</script>
<style lang="less">

.gaoding-uupoop-ps-draw {
    @layout-width: 1200px;
    @scale: 0.95;

    box-sizing: border-box;
    width: 100%;
    height: 489px;
    padding-top: 64px;

    .gaoding-uupoop-ps-draw-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        width: 1200px;
        height: 340px;
        margin: auto;
        background-image: url('https://cdn.dancf.com/fe-assets/20240605/2c71a0d4b97fb83db3d6047697572a64.png');
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (max-width: 1440px) {
            width: calc(@layout-width * @scale);
        }
    }

    .gaoding-uupoop-ps-draw-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 269px;
    }

    .gaoding-uupoop-ps-draw-divider {
        width: 1.25px;
        height: 35px;
        background-color:rgba(0,0,0, 0.08 )
    }

    .gaoding-uupoop-ps-draw-desc {
        font-family: 'PingFang SC';
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 37.5px;
        color: #222529;
    }

    .gaoding-uupoop-ps-draw-title {
        margin-top: 14px;
        margin-bottom: 39px;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: #4c535c;
        text-align: center;
    }

    img {
        width: 80px;
        height: 35px;
    }

    .gda-btn {
        display: inline-block;
        width: 200px;
        height: 52px;
        line-height: 52px;
        text-decoration: none;
    }

    .gaoding-uupoop-ps-draw-button {
        display: inline-block;
        width: 200px;
        height: 52px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 52px;
        color: #222529;
        text-align: center;
        text-decoration: none;
        background: rgba(179, 179, 179, 0.1);
        border: 1px solid #222529;
        border-radius: 2px;
        &:hover {
            color: #222529;
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #222529;
        }

        &:active {
            color: #222529;
            background: rgba(255, 255, 255, 0.05);
            border: 1px solid #222529;
        }
    }
}
</style>
