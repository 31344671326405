export const headDataList = [{
    title: '在线PS专业版',
    isMenu: true,
    id: 1,
}, {
    title: '在线PS精简版',
    isMenu: true,
    id: 2,
}, {
    title: '抠图/工具',
    isMenu: true,
    id: 3
}, {
    title: '模板素材',
    isMenu: true,
    id: 4
}]

export const moreLink = [{
    title: '稿定设计官网',
    desc: '海量海报模版，让设计更简单',
    icon: 'https://st-gdx.dancf.com/gaodingx/213/configs/system/20200907-115221-dd97.svg',
    href: 'https://gaoding.com',
}, {
    title: '稿定抠图',
    desc: '在线删除图片背景',
    icon: 'https://st-gdx.dancf.com/gaodingx/213/configs/system/20200907-115420-5603.svg',
    href: 'https://koutu.gaoding.com/'
}, {
    title: '稿定素材',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-144117-ed0d.png',
    href: 'https://sucai.gaoding.com/'
}, {
    title: '在线PS',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-143917-d7ed.png',
    href: 'https://ps.gaoding.com/#/'
}, {
    title: '一键生成海报',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-144044-eebb.png',
    href: 'https://www.gaoding.com/poster-generator'
}, {
    title: '在线剪视频',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-143854-cc41.png',
    href: 'https://www.gaoding.com/clipper'
}, {
    title: '图片编辑器',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-143941-ab0d.png',
    href: 'https://www.gaoding.com/image'
}, {
    title: '在线拼图',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-144004-5828.png',
    href: 'https://www.gaoding.com/pintu'
}, {
    title: 'PPT插件',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-144021-68a6.png',
    href: 'https://www.gaoding.com/ppt/plugin'
}, {
    title: '手机网页制作',
    icon: 'https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211209-143728-942c.png',
    href: 'https://www.gaoding.com/odyssey/design'
}]


export const functionDetailList = [
    {
        title: '图片标记',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/149c8aea5579bb968cdef120fbb48de5.svg',
        id: 1,
    },
    {
        title: '去除水印',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/867033ed58b60749f21f53f43ef94f94.svg',
        id: 2,
    },
    {
        title: '特效滤镜',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/65398c49847f57ee2e53c1abf2e1783b.svg',
        id: 3,
    },
    {
        title: '批量操作',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/edcf4c187e4a558efe276cf28d872926.svg',
        id: 4,
    },
    {
        title: '快捷裁剪',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/404074ad98f531eecc5470b26b838476.svg',
        id: 5,
    },
    {
        title: '添加水印',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/8b4b781f73c973cf83e5ad1122ebcaef.svg',
        id: 6,
    },
];
export const videosList = [
    {
        id: 1,
        url:
            "https://st0.dancf.com/csc/1681/configs/system/20210021-110338-ea07.mp4",
        alt: "图片编辑器，一键裁剪",
        time: 10,
        color: '#ffffff',
    },
    {
        id: 2,
        url:
            "https://st0.dancf.com/csc/1681/configs/system/20210021-110540-87b3.mp4",
        alt: "图片编辑器，无损压缩",
        time: 9,
        color: '#ffffff',
    },
    {
        id: 3,
        url:
            "https://st0.dancf.com/csc/1681/configs/system/20210021-110433-edae.mp4",
        alt: "图片编辑器，轻松消除",
        time: 7,
        color: '#ffffff',
    },
    {
        id: 4,
        url:
            "https://st0.dancf.com/csc/1681/configs/system/20210021-110555-88d2.mp4",
        alt: "图片编辑器，批量处理",
        time: 10,
        color: '#33383E',
    },
];

export const toolList = [
    {
        title: '一站式工具',
        desc: '专业级工具 一站式“稿定”设计',
    },
    {
        title: '在线拼图',
        desc: '多种拼图',
        link: '模板任选',
        trackText: '跳转拼图',
        href: '/editor/collage?id=83883',
        icon: 'https://cdn.dancf.com/fe-assets/20240605/35e4f25bd72ae1fd8f4fe5f5dfe7f213.png',
    },
    {
        title: '证件照换底色',
        desc: '批量加字',
        link: '商品图打水印',
        trackText: '跳转证件照',
        href: '/koutu',
        icon: 'https://cdn.dancf.com/fe-assets/20240605/d4b0548aabf0e1d37ce24a57ca056a2c.png',
    },
    {
        title: '加水印',
        desc: '图片盖个戳',
        link: '平台好传播',
        trackText: '跳转图片编辑器',
        href: '/editor/image?func=watermark',
        icon: 'https://cdn.dancf.com/fe-assets/20240605/06ef15fdc09f9e2ca1bcc21e08d4794e.png',
    },
    {
        title: '更多工具',
        desc: '更多工具',
        link: '尽在稿定',
        trackText: '跳转更多工具',
        href: '/create-design',
        icon: 'https://cdn.dancf.com/fe-assets/20240605/c6f6dc3b033a0f07149373348654b06a.png',
    },
];


export const menus = [
    {
        title: "",
        children: [
            { title: "为你推荐", icon: 'IconCompass', href: "/" },
            { title: "稿定AI", icon: 'IconAiStroke', href: "/ai" },
            { title: "稿定模板", icon: 'IconTemplate', href: "/templates" },
        ]
    },
    {
        title: "工作台",
        children: [
            { title: "最近打开", icon: "IconRecent", href: '/dam/recently' },
            { title: "我的空间", icon: "IconIdPhoto", href: '/dam/my' },
        ]
    },
    {
        title: "添加快捷访问",
        children: [
            { title: "智能设计", icon: "IconSmartDesign", href: "/ai/design", limitTimeIcon: 'https://cdn.dancf.com/fe-assets/20240319/8c056d9e5200669216f15287e44f25fd.svg' },
            { title: "图片编辑", icon: "IconPhotoEditor", href: "/editor/image?type=image" },
            { title: "在线抠图", icon: "IconCutout", href: "/koutu" },
        ]
    },
    {
        title: "创建设计",
        children: [
            { title: "设计工具", icon: 'IconTool', href: "/create-design" },
            { title: "打开本地", icon: 'IconOpenFile', href: "/uupoop?parse=1" },
        ]
    }
]

export const detailList = [
    {
        title: '兼容全图片格式',
        desc: '兼容全图片文件编辑能力',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/2e644a48f4123dd1d0d83bdf07493ec9.png',
    },
    {
        title: '原生图像工具',
        desc: '满足90%专业作图需求',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/4d07a87b07b787880cd275b44f0eadbf.png',
    },
    {
        title: '适配快捷键',
        desc: '完美适配PS快捷键组合操作',
        icon: 'https://cdn.dancf.com/fe-assets/20240528/de3b4981aafef2b271fc3f8248476730.png',
    },
];

export const templetMap = {
    image: {
        title: '图片模板',
        backgroundImage:
            'https://cdn.dancf.com/fe-assets/20240529/9cee955d07f3fb4e242b6d721f259d00.png',
        path: '/templates/image',
    },
    h5: {
        title: 'H5网页模板',
        backgroundImage:
            'https://cdn.dancf.com/fe-assets/20240529/e7f244cbd9e421361d3926fe99651856.png',
        path: '/templates/h5',
    },
    ppt: {
        title: 'PPT模板',
        backgroundImage:
            'https://cdn.dancf.com/fe-assets/20240529/cbaf11f44959f954f5044785a69de6b1.png',
        path: '/templates/ppt',
    },
    video: {
        title: '视频模板',
        backgroundImage:
            'https://cdn.dancf.com/fe-assets/20240529/49075d6e004196f529a83da3731f760e.png',
        path: '/templates/video',
    },
};

export const AiMap = {
    design: {
        order: 1,
        title: 'AI设计',
        desc: '创意无限，设计随心所欲',
        image: 'https://cdn.dancf.com/fe-assets/20240529/3b7309b023e0d0b6c82f344762398d9e.png',
        backgroundColor: '#9BD562',
        path: '/ai/design',
    },
    image: {
        order: 2,
        title: 'AI绘画',
        desc: '让AI拓展你的灵感边界',
        image: 'https://cdn.dancf.com/fe-assets/20240529/64007badb3f5daaa5ed45cd782943f95.png ',
        backgroundColor: '#5EC4DB',
        path: '/ai/image',
    },
    material: {
        order: 3,
        title: 'AI素材',
        desc: '告别海淘素材资源',
        image: 'https://cdn.dancf.com/fe-assets/20240529/db4fd3d9c2c50d231afa8492e463e495.png',
        backgroundColor: '#6979E9',
        path: '/ai/material',
    },
    writer: {
        order: 3,
        title: 'AI文案',
        desc: '你的专属写作助理',
        image: 'https://cdn.dancf.com/fe-assets/20240529/3331a01574ae972c1d7532cb521dfe02.png',
        backgroundColor: '#57AEFE',
        path: '/ai/writer',
    },
};




export const footerLinks = [
    {
        title: '关于稿定',
        href: 'https://gaoding.com/about-us',
        rel: 'nofollow',
    },
    {
        title: '稿定设计',
        href: `https://gaoding.com`,
        rel: 'nofollow',
    },
    {
        title: '加入我们',
        rel: 'nofollow',
        href: 'https://app.mokahr.com/m/apply/huanleguang/1954#/home',
    },
    {
        title: '用户协议',
        href: `https://gaoding.com/term/user_service`,
        rel: 'nofollow',
    },
    {
        title: '隐私策略',
        href: `https://gaoding.com/term/privacy`,
        rel: 'nofollow',
    },
    {
        title: '授权协议',
        href: `https://gaoding.com/term/license_agreement`,
        rel: 'nofollow',
    },
    {
        title: '帮助中心',
        rel: 'nofollow',
        href: 'https://gaoding.com/help-center',
    },
    {
        title: '功能介绍',
        rel: 'nofollow',
        href: ' https://www.gaoding.com/features',
        tooltips: [
            {
                index: 1,
                title: '手机海报设计',
            },
            {
                index: 2,
                title: '名片设计',
            },
            {
                index: 3,
                title: '插画设计',
            },
            {
                index: 4,
                title: '菜单制作',
            },
            {
                index: 5,
                title: 'PPT模板设计',
            },
            {
                index: 6,
                title: '手机壁纸设计',
            },
            {
                index: 7,
                title: '公众号素材',
            },
            {
                index: 8,
                title: '宣传单设计',
            },
            {
                index: 9,
                title: '邀请函设计',
            },
            {
                index: 10,
                title: '日签设计',
            },
        ],
    },
    {
        title: '友情链接',
        href: "https://www.gaoding.com/",
        // tooltipsComponent: FriendlyLinks,
    },
    {
        title: '最新文章',
        href: 'https://gaoding.com/article/',
    },
    {
        title: '资讯中心',
        href: `https://gaoding.com/information/`,
        rel: 'nofollow',
    },
    {
        title: '意见反馈',
        href: 'https://support.qq.com/products/161838',
        rel: 'nofollow',
    },
];
