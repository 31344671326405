import { useScriptTag } from '../utils/use-script-tags';

declare global {
    interface Window {
        OpenInstall: any;
    }
}

 // 是否实例化完成
export let isReady = false;
export let openInstall;
export function useOpenInstall(channel) {
    /**
     * 加载脚本
     */
    function loadSDK() {
        if (!window.OpenInstall) {
            const { load } = useScriptTag('https://cdn.dancf.com/fe-assets/20240923/69bf137b8e03aa5285c157bbbabbcfa6.js', install);
            load();
        } else {
            install();
        }
    }

    /**
     * 启动
     */
    function install() {
        const { OpenInstall } = window;

        if (!OpenInstall) {
            return;
        }

        const urlParams = OpenInstall.parseUrlParams();
      
        const data = {
            ...urlParams,
            channelCode: channel,
        };

        openInstall = new OpenInstall(
            {
                appKey: 'm7x2jb',
                channelCode: channel,
                onready() {
                   
                    isReady = true;
                    console.log('onready', isReady);
                    openInstall.schemeWakeup();
                },
            },
            data,
        );
    }
    loadSDK();

    return {
        isReady,
        openInstall,
    };
}
