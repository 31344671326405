<template>
  <div class="footer">
    <udesk
      class="feedback"
      :config="{
        panel: {
          css: { right: '0px' }
        }
      }"
    >
      反馈我们
    </udesk>
    <a href="https://www.gaoding.com/about" target="_blank">关于我们</a>
    <a href="https://www.gaoding.com" target="_blank">友情链接</a>
    <span class="record">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        闽ICP备18003982号-4
      </a>
      &nbsp;| &nbsp;
      <span>©2017 - {{new Date().getFullYear()}} 稿定（厦门）科技有限公司</span>
      &nbsp;| &nbsp;
    </span>
    <span class="security">
      <img src="../assets/record.svg" />
      <span>互联网安全备案号:{{ record }}</span>
    </span>
    <span class="nav">
        &nbsp;| &nbsp;
        <span>版本号: </span>
        <span class="margin"> iOS:{{this.iosVersion}}</span>
        <span class="margin"> &nbsp;&nbsp;Android:{{this.androidVersion}}</span>
        &nbsp;|&nbsp;
    </span>
    <a class="nav" target="_blank" href="https://www.gaoding.com/article/20526">&nbsp;用户协议&隐私协议</a>
  </div>
</template>

<script>
import { Udesk } from '@gaoding/gd-components';
import {getAppVErsion} from '../apis/index';
import to from 'await-to-js';
import {get} from 'lodash'
export default {
  components: { Udesk },
  data() {
    return {
      isPs: window.top === window,
      iosVersion: '4.5.3',
      androidVersion: '4.5.5',
    };
  },
  computed: {
    record() {
      return this.isPs ? '35020602001758' : '35020602001764';
    }
  },
  mounted() {
    this.getVersion();
  },
  methods: {
    async getVersion() {
      const [error, data] = await to(getAppVErsion());
      if (error) return;
      this.iosVersion = get(data, 'data.ios.version', '4.5.3');
      this.androidVersion = get(data, 'data.android.version', '4.5.5');
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  height: 32px;
  background-color: #464748;
  white-space: nowrap;
  overflow: hidden;
  a,
  span,
  .feedback {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
  }
  & > a:not(.nav){
    margin-left: 20px;
    opacity: 0.6;
  }
  .feedback {
    opacity: 0.6;
    display: inline-block;
    cursor: pointer;
    margin-left: 24px;
  }
  .record {
    margin-left: 36px;
    opacity: 0.4;
    color: #ffffff;
  }
  .security {
    img {
      opacity: 1 !important;
      position: relative;
      top: 1.5px;
      margin-right: 3px;
    }
    span {
      opacity: 0.4;
    }
  }

  .nav{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
    opacity: 0.4;
    >span{
        display: inline-block;
    }
    .margin{
      margin-left: 8px;
    }
  }
}
</style>
