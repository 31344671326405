<template>
    <div class="gaoding-uupoop-banner-container" v-if="materials.length">
        <div class="gaoding-uupoop-banner">
            <transition-group name="fade" class="gaoding-uupoop-banner-transition-group">
                <div v-for="item in materials" :key="item.id" :class="['gaoding-uupoop-banner-item', { 'gaoding-uupoop-banner-item-show': item.id === activeId}]">
                    <img :src="item.search_img_url" @click="linkTo(item)"/>
                </div>
            </transition-group>
            <div class="gaoding-uupoop-banner-points" v-if="materials.length > 1">
                <template v-for="item in materials">
                    <span
                        @click="onClick(item.id)"
                        :key="item.id"
                        class="gaoding-uupoop-banner-points-point"
                        :style="{
                            backgroundColor: activeId === item.id ? 'white' : 'rgba(0,0,0,0.4)',
                        }"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getBanners } from '../../apis'
import { resourceTracker } from "../../trackers"

export default {
    data() {
        return {
            activeId: 1,
            materials: []
        };
    },
    watch: {
        activeId: {
            handler(val) {
                this.playBanner();
                const target  = this.materials.find(item => item.id)
                resourceTracker.expose({
                    page_name: "UUPOOP页",
                    module_name: "底部banner",
                    subject_name: "资源位",
                    resource_id: '0',
                    resource_title: target.title,
                    resource_location: `${val}`,
                })
            },
        },
    },
    methods: {
        linkTo(item){
            if(item.url){
                window.open(item.url)
                resourceTracker.click({
                    page_name: "UUPOOP页",
                    module_name: "底部banner",
                    subject_name: "资源位",
                    resource_id: '0',
                    resource_title: item.title,
                    resource_location: `${this.activeId}`,
                })
            }
        },
        playBanner(){
            clearTimeout(this.handler);
            this.handler = setTimeout(() => {
                let value = this.activeId;
                value = this.activeId  >= this.materials.length ? 1 : value + 1;
                this.setActiveId(value);
            }, 5 * 1000);
        },
        onClick(id) {
            this.setActiveId(id);
        },
        setActiveId(value) {
            this.activeId = value;
        },
        async getBanners(){
            const { data: { pit_modules } } = await getBanners();
            let id = 0;
            this.materials = (pit_modules||[]).map(item => {
                return {
                    ...item.material,
                    title: item.pit_name,
                    id: ++id,
                }
            });
        }
    },
    async mounted() {
        await this.getBanners();
        this.playBanner()
    },
};
</script>

<style lang="less">
.gaoding-uupoop-banner-container {
    @layout-width: 1200px;
    @scale: 0.95;
    width: 1200px;
    height: 150px;
    margin: auto;
    padding-bottom: 120px;
     @media screen and (max-width: 1440px) {
        width: @layout-width * @scale;
        min-width: @layout-width * @scale;
    }
    .gaoding-uupoop-banner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .carousel-points {
            bottom: 60px;
        }
        .gaoding-uupoop-banner-transition-group {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
        }

        &-item {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            position: absolute;
            transition: all .5s linear;
            &-show {
                position: absolute;
                opacity: 1;
            }
            img {
                cursor: pointer;
                width: 100%;
                height: auto;
                border-radius: 12px;
            }
        }

        &-points {
            z-index: 100;
            width: 66px;
            height: 18px;
            background: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(16px);
            border-radius: 16px;
            position: absolute;
            bottom: -45px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 13px;
            &-point {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

</style>
