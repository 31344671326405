<template>
    <svg
        role="img"
        aria-label="photo-editor"
        class="gd_design_icon gd_design_icon-photo-editor"
        focusable="false"
        data-icon="photo-editor"
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.7481 10.1433C11.4787 9.7122 11.1121 9.34981 10.6773 9.08531C10.546 9.00459 10.4061 8.93246 10.2629 8.87063C9.64904 8.60785 9.01471 8.45671 8.36333 8.41893C8.2576 8.41377 8.15018 8.41034 8.04275 8.41034C7.05885 8.41034 6.11929 8.66281 5.22236 9.16947C4.53005 9.5559 3.86332 10.0952 3.22387 10.7839C2.92887 11.1016 2.78223 11.5087 2.78223 11.914C2.78223 12.3193 2.92887 12.7266 3.22387 13.0441C3.86332 13.7328 4.53005 14.2723 5.22236 14.6587"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M14.474 18.5832C14.0801 19.344 13.5106 20.0757 12.7637 20.7747C12.4482 21.07 12.0475 21.2178 11.6451 21.2178C11.2426 21.2178 10.8419 21.07 10.5265 20.7747C9.77959 20.0757 9.21006 19.344 8.81616 18.5832C8.37451 17.733 8.15454 16.8451 8.15454 15.9175C8.15454 15.7457 8.16136 15.5759 8.17841 15.4076C8.23298 14.7944 8.38645 14.1984 8.64052 13.6214C8.94296 12.926 9.49157 12.3443 10.1684 12.0001C10.2059 12.019 10.2434 12.0378 10.2826 12.055H10.2843"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M10.8276 12.6423C10.8122 12.6767 10.7986 12.7109 10.785 12.7469C10.744 12.8519 10.7099 12.9584 10.6809 13.0666C10.5292 13.6144 10.5224 14.1932 10.6588 14.7427C10.6946 14.8905 10.7406 15.0363 10.7969 15.1789C11.0442 15.8042 11.3869 16.3605 11.8234 16.8502C11.895 16.9343 11.9718 17.0151 12.0502 17.0941C12.763 17.8103 13.6309 18.3064 14.6523 18.5831C15.3992 18.7875 16.2296 18.8734 17.1419 18.8408C17.5733 18.8253 17.9621 18.6431 18.2469 18.3562C18.5317 18.0696 18.7124 17.6778 18.7261 17.2435C18.7585 16.292 18.6647 15.4296 18.4447 14.6587"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M11.9348 13.1722C11.957 13.2306 11.9791 13.289 12.003 13.3457C12.0491 13.4539 12.1002 13.5587 12.1565 13.66C12.4344 14.1615 12.8352 14.5823 13.3228 14.8777C13.4541 14.9584 13.594 15.0306 13.7372 15.0924C14.3477 15.3534 14.9769 15.5046 15.6231 15.5424C15.734 15.5492 15.8448 15.5527 15.9574 15.5527C16.9413 15.5527 17.8808 15.3002 18.7778 14.7935C19.4701 14.4071 20.1368 13.8678 20.7762 13.1791C21.0712 12.8614 21.2179 12.4543 21.2179 12.049C21.2179 11.6437 21.0712 11.2366 20.7762 10.9189C20.1368 10.2302 19.4701 9.69088 18.7778 9.30444"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M12.5225 12.8462C12.5634 12.8634 12.6026 12.8806 12.6435 12.8977C12.7561 12.9424 12.872 12.9819 12.988 13.0145C13.5047 13.1588 14.0469 13.1742 14.5687 13.0575C14.7341 13.0231 14.8978 12.9733 15.0581 12.9097C15.6975 12.659 16.2671 12.3086 16.7667 11.8621C16.8485 11.7899 16.9287 11.7144 17.0054 11.6371C17.6977 10.9432 18.1837 10.1034 18.4651 9.11753C18.685 8.34638 18.7788 7.4842 18.7464 6.53271C18.7328 6.09818 18.552 5.7066 18.2673 5.41978C17.9825 5.13295 17.5937 4.9509 17.1623 4.93544C16.2176 4.90281 15.3616 4.99556 14.5943 5.21539"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M13.1946 11.9741C13.2116 11.9827 13.2304 11.9913 13.2474 11.9999C13.8067 11.7165 14.2706 11.2785 14.5894 10.741C14.6576 10.6242 14.7207 10.5039 14.7753 10.3786C15.0532 9.74652 15.2118 9.09216 15.251 8.4189C15.2579 8.30727 15.2613 8.19563 15.2613 8.08228C15.2613 7.10159 15.014 6.16212 14.5212 5.26731C14.1307 4.55971 13.5885 3.87786 12.8893 3.22522C12.5739 2.92981 12.1732 2.7821 11.7707 2.7821C11.3683 2.7821 10.9676 2.92981 10.6521 3.22522C9.953 3.87786 9.41075 4.55971 9.02026 5.26731"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M13.0032 10.6944C13.1379 10.1654 13.1396 9.6124 13.0083 9.08513C12.9725 8.93743 12.9265 8.79144 12.8702 8.64889C12.623 8.02372 12.2802 7.46726 11.8437 6.97777C11.7721 6.89361 11.6953 6.81289 11.6169 6.73389C10.9212 6.03659 10.0805 5.5471 9.0932 5.26715C8.32586 5.04731 7.46986 4.95457 6.52518 4.9872C6.09376 5.00266 5.70498 5.18471 5.42021 5.47153C5.13545 5.75835 4.95469 6.14994 4.94105 6.58447C4.90865 7.53596 5.00244 8.39813 5.22241 9.16929"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M9.01476 18.583C8.26789 18.7874 7.43746 18.8733 6.52518 18.8407C6.09376 18.8252 5.70498 18.6432 5.42021 18.3563C5.13545 18.0695 4.95469 17.6778 4.94105 17.2434C4.90865 16.2919 5.00244 15.4297 5.22241 14.6586C5.50377 13.6727 5.98975 12.8327 6.68206 12.139C6.75879 12.0617 6.83893 11.9862 6.92078 11.914C7.42041 11.4675 7.98994 11.1171 8.62939 10.8664C8.75898 10.8148 8.89028 10.7736 9.02499 10.741C9.56725 10.6019 10.1368 10.607 10.6773 10.7564"
            stroke="currentColor"
            stroke-width="1.2"
        />
        <path
            d="M13.8647 12.1358L13.8649 12.1351C13.9114 11.9626 13.9357 11.7832 13.9357 11.598C13.9357 11.1478 13.791 10.7279 13.5468 10.3879C13.5245 10.3561 13.5018 10.3275 13.49 10.3128C13.4897 10.3124 13.4894 10.312 13.4891 10.3116C13.1329 9.85652 12.5896 9.55516 11.9753 9.52215C11.9309 9.51935 11.8912 9.51937 11.868 9.51938C11.8667 9.51938 11.8654 9.51938 11.8641 9.51938C11.8085 9.51938 11.7518 9.52175 11.6957 9.52677C11.0878 9.57603 10.5544 9.888 10.2098 10.3466C10.1907 10.3697 10.1707 10.3968 10.1518 10.4284C9.92325 10.7621 9.79087 11.1672 9.79087 11.598C9.79087 11.7724 9.81245 11.9452 9.85395 12.1099L9.85393 12.1099L9.85526 12.115C9.85566 12.1166 9.85608 12.1182 9.85651 12.1198C9.86436 12.1499 9.87724 12.1993 9.89379 12.2493L10.03 12.6608H10.0821C10.1741 12.816 10.2859 12.9582 10.4143 13.0838C10.5555 13.223 10.7164 13.3412 10.8941 13.4347C11.0849 13.5365 11.2941 13.61 11.5182 13.6473C11.6326 13.6672 11.7492 13.6767 11.8641 13.6767C11.9621 13.6767 12.0561 13.6691 12.1433 13.6576L12.1433 13.6576L12.1456 13.6573C12.3783 13.6255 12.6006 13.5547 12.8045 13.4489C12.9731 13.3635 13.1315 13.2535 13.2721 13.12C13.5363 12.877 13.7344 12.5664 13.8431 12.216L13.8463 12.2055L13.8492 12.195L13.8586 12.159L13.8647 12.1358Z"
            fill="white"
            stroke="currentColor"
            stroke-width="1.2"
        />
    </svg>
</template>

<script>
import './index.less';

export default {
    name: 'IconPhotoEditor',
};
</script>
