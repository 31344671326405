var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gaoding-uupoop-root-v2-matting-image"},[_c('article',[(!_vm.isMobile)?_c('video',{attrs:{"muted":"","loop":"","autoplay":"","width":"687","src":"https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211215-134509-3209.mp4"},domProps:{"muted":true}}):_vm._e(),_c('div',{staticClass:"title"},[_c('h5',[_vm._v("AI\"黑科技\"，一键抠图")]),_c('TrackButton',{attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: '马上抠图'
            }}},[_c('AButton',{ref:"button",staticClass:"matting-image-btn",on:{"click":_vm.handleClick}},[_vm._v(" 马上抠图 ")])],1)],1)]),_c('article',{staticClass:"icon-detail"},_vm._l((_vm.toolList),function(item,index){return _c('div',{key:item.title,class:{'all-title': index === 0, 'unit-title': index !== 0},on:{"click":function($event){return _vm.handleALink(item)}}},[_c('TrackButton',{attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: item.title
            }}},[_c('div',[(item.icon)?_c('img',{attrs:{"src":item.icon,"alt":item.title}}):_vm._e(),_c('h5',[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"desc"},[_c('span',[_vm._v(_vm._s(item.desc))]),(item.link)?_c('a',{attrs:{"href":item.href,"target":"_blank"}},[_vm._v(_vm._s(item.link)+" >")]):_vm._e()])])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }