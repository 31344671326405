import UserSession, { userSession } from './user-session';
import { tracker } from '@gaoding/gd-tracker';
import {get} from 'lodash';
/**
 * @param {number} isSlientLogin 是否是静默登录: 1 是, 0 否
 * @param {number} e 登录成功后传入的 event 数据
 * @description 登录成功后回调, 包括 静默登录 / 弹窗登录
 *  */
 function initTrackerLogin({ isSlientLogin = 0, ...e }) {
    const trackerOpts = {
        event_id: 39001,
        is_cache_login: isSlientLogin
    };
    let userInfo = {};
    // 埋点字段
    // is_verify_code_login: 是否验证码登录
    // is_cache_login: 是否静默登录

    if (isSlientLogin) {
        userInfo = get(userSession, 'user', {});
        
        Object.assign(trackerOpts, { is_verify_code_login: 0, type: 1, login_type: 'auto_login' });
    } else {
        let { type } = e;
        userInfo = e.data;
        const isVerifyCodeLogin = type === 'verify-code';
        if (isVerifyCodeLogin) {
            type = 'mobile';
        }

        Object.assign(trackerOpts, {
            login_type: type || 'mobile',
            type: userInfo.type,
            is_verify_code_login: Number(isVerifyCodeLogin)
        });
    }

    tracker.login(
        userInfo.id,
        Object.assign(trackerOpts, {
            vipLevel: get(userInfo, 'vip.level')
        })
    );
}

async function initUserSession() {
    const userEvents = UserSession.Events;
    userSession.on(userEvents.LOGIN, e => {
        initTrackerLogin(e);
    });
    await userSession.loginGuest();
}
initUserSession()