var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-v2-btn"},[_c('TrackButton',{attrs:{"trackData":{
            page_name: 'UUPOOP页',
            module_name: '创作类型选择',
            button_name: _vm.primaryText
        }}},[_c('AButton',{staticClass:"professional",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.$emit('primary')}}},[_vm._v(_vm._s(_vm.primaryText))])],1),_c('TrackButton',{attrs:{"trackData":{
            page_name: 'UUPOOP页',
            module_name: '创作类型选择',
            button_name: _vm.secondText
        }}},[_c('AButton',{staticClass:"simplify",attrs:{"target":"_blank","href":_vm.secondLink},on:{"click":function($event){return _vm.$emit('second')}}},[_vm._v(_vm._s(_vm.secondText))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }