<template>
    <section class="gaoding-uupoop-pro">
        <div class="title-img">
            <img :src="ossUrl('https://cdn.dancf.com/fe-assets/20240528/ad8d9ca54e407ea7f49827e9b6c419ea.png')" alt="在线PS专业版title">
        </div>
        <PublicBtn
            primaryText="导入图片/PSD"
            secondText="在线PS专业版"
            primaryLink="/#/editor"
            :secondLink="primaryLink"
            @primary="handlePrimary"
            @second="handleSecond"
            ref="button"
            class="pro-btn"
        />
        <div class="video-wrap">
            <img
                class="video-img"
                :src="
                    ossUrl(
                        'https://cdn.dancf.com/fe-assets/20240605/6263b48f8c450fb5860ebe51a899f035.png',
                    )
                "
            />
            <video
                v-if="!isMobile"
                muted
                v-show="showVideo"
                poster="https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211213-103749-b128.png"
                ref="videoOnecRef"
                @ended="handleEnded"
                src="https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211215-134408-52a3.mp4">
            </video>
            <video muted autoplay loop v-show="!showVideo"
            v-if="!isMobile"
            ref="videoLoopcRef"
            src="https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211215-134448-8f72.mp4" alt="首屏背景"></video>
        </div>
        <article class="detail">
            <nav v-for="item in detailList" :key="item.title">
                <img :src="item.icon" :alt="item.title">
                <h5>{{item.title}}</h5>
                <p>{{item.desc}}</p>
            </nav>
        </article>
    </section>
</template>
<script>
import {Button} from '@gaoding/gd-antd';
import {detailList} from './constant.js';
import PublicBtn from './btn.vue';
import env from '../../services/env';
import {ossUrl} from '@gaodingx/shared/lib'
import { linkTo } from  "../../utils/query"
import { GAODING_UUPOOP_ENV } from "../../services/env"
import { platform } from '@gdesign/platform';
export default {
    name: 'uupoop-root-v2-ps-pro',
    components: {
        AButton: Button,
        PublicBtn,
    },
    data() {
        return {
            detailList,
            showVideo: true,
            platform,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl
        }
    },
    mounted(){
        if (platform.isMobile) {
            this.showVideo = false;
            return
        }
        const videoOnecRef = this.$refs.videoOnecRef;

        if(videoOnecRef) {
            videoOnecRef.play()
        }
    },
    computed: {
         primaryLink() {
             return env.psUrl
         },
         isMobile() {
            return platform.isMobile
         }
    },
    methods: {
        ossUrl,
        handlePrimary() {
            linkTo(`${this.gaodingUrl}/uupoop?parse=1`)
        },
        handleSecond() {

        },
        handleEnded() {
            this.showVideo = false;
            const videoLoopRef = this.$refs.videoLoopRef;
            if(videoLoopRef) {
                videoLoopRef.play()
            }
        }
    }
}
</script>
<style lang="less">
.gaoding-uupoop-pro {
    @layout-width: 1200px;
    @scale: 0.95;

    padding-top: 20px;
    width: @layout-width;
    background-position: revert;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    @media screen and (max-width: 1440px) {
        width: @layout-width * @scale;
        min-width: @layout-width * @scale;
    }

    .title-img {
        width: 621px;
        height: 120px;
        padding-top: 58px;
        padding-bottom: 35px;
        margin: auto;
        box-sizing: content-box;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .pro-btn {
        margin-bottom: 96px;
    }

    .detail {
        width: @layout-width;
        min-width: @layout-width;
        display: flex;
        justify-content: space-around;
        margin: auto;
        padding: 64px 0 128px 0;
        text-align: center;

        @media screen and (max-width: 1440px) {
            width: @layout-width * @scale;
            min-width: @layout-width * @scale;
        }

        nav {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-around;
        }
        img {
            width: 64px;
            height: 64px;
            margin: auto;
        }

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #222529;
            margin-top: 24px;
            margin-bottom: 0;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #222529;
            margin-top: 12px;
            margin-bottom: 0;
        }
    }
    .video-wrap {
        position: relative;
        width: @layout-width;
        height: 644px;
        margin: auto;

        @media screen and (max-width: 1440px) {
            width: @layout-width * @scale;
            min-width: @layout-width * @scale;
        }

        .video-img {
            position: absolute;
            top: -300px;
            z-index: -1;
            height: 1292px;
        }
        video {
            width: 100%;
            border-radius: 10px;
        }
        img {
            width: 100%;
            height: 100%;
        }

    }
    .btn {
        text-align: center;
        button {
            width: 200px;
            height: 52px;
            margin-bottom: 97px;
        }
        .professional {
            margin-right: 20px;
            background: #2254F4;
            color: #fff;
            font-size: 16px;
            border-color: #2254F4;
            &:hover {
                background: #4D7CFF;
                border-color: #4D7CFF;
            }
            &:active {
                background: #1F4DE0;
                border-color: #1F4DE0;
            }
        }
        .simplify {
            background: #2A2B31;
            border-color: #2A2B31;
            font-size: 16px;
            color: #fff;
            &:hover {
                background: #42434B;
                border-color: #42434B;
            }
            &:active {
                background: #27282F;
                border-color: #27282F;
            }
        }
    }
}
</style>
