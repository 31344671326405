<template>
    <div class="carousel-container">
        <div class="carousel">
            <transition-group name="fade" class="transition-group">
                <template v-for="video in videosList">
                    <div :class="['carousel-item', {'show': video.id === activeId}]" :key="video.id">
                        <video
                            :id="'video-' + video.id"
                            :src="video.url"
                            width="685"
                            autoplay
                            muted
                            autobuffer
                            preload="auto"
                        />
                    </div>
                </template>
            </transition-group>
            <h3 class="carousel-title">{{ activeVideo.alt }}</h3>
            <div class="carousel-points">
                <template v-for="img in videosList">
                    <span
                        @click="onClick(img.id)"
                        :key="img.id"
                        class="carousel-points-point"
                        :style="{
                            backgroundColor: activeId === img.id ? 'white' : 'rgba(0,0,0,0.4)',
                        }"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { videosList } from './constant.js';
import { platform } from '@gdesign/platform';

export default {
    name: "root-v2-carousel",
    data() {
        return {
            activeId: 1,
            videosList
        };
    },
    watch: {
        activeId: {
            handler() {
               
                this.playCarousel();
            },
        },
    },
    computed: {
        activeVideo() {
            return this.videosList.find(img => img.id === this.activeId);
        }
    },
    methods: {
        playCarousel() {
            if (platform.isMobile) {
                return;
            }
            clearTimeout(this.handler);
            this.$nextTick(() => {
                const video = document.querySelector(`#video-${this.activeId}`);
                video.currentTime = 1;
                video.play();
            });

            this.handler = setTimeout(() => {
                let value = this.activeId;
                if (this.activeId >= 4) {
                    value = 1;
                } else {
                    value += 1;
                }
                this.setActiveId(value);
            }, this.activeVideo.time * 1000);
        },
        onClick(id) {
            this.setActiveId(id);
        },
        setActiveId(value) {
            this.activeId = value;
        },
    },
    mounted() {
        this.playCarousel();
    },
};
</script>

<style lang="less">
.carousel-container {
    width: 685px;
    height: 582px;
}
.carousel {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .carousel-title {
        color: #fff;
        bottom: 95px;
    }
    .carousel-points {
        bottom: 60px;
    }
    .transition-group {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .show {
        position: absolute;
        opacity: 1;
    }
    &-item {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: absolute;
        transition: all .5s linear;
        video {
            border-radius: 12px;
        }
    }
    &-title {
        position: absolute;
        bottom: 198px;
        color: #FFFFFF;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 42px;
        transition: color .5s linear .5s;
    }
    &-points {
        z-index: 100;
        width: 88px;
        height: 32px;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(16px);
        border-radius: 16px;
        position: absolute;
        bottom: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 13px;
        &-point {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}
</style>
