/**
 * services/load-image
 */

import Promise from 'bluebird';
import loadImage from './load-image';

export default (url = '') => {
    return Promise.try(() => {
        return loadImage(url);
    })
        .then(img => {
            return {
                height: img.height,
                width: img.width
            };
        });
};
