/**
 * abtest 组件
 * 新版 abtest 配合新的埋点SDK
 */
import gdAbTest from '@gaoding/gd-abtest';
import { cloneDeep, isNil } from 'lodash';
import { getUserDeviceId } from '@gaoding/user-device-id';
import { event } from './event';
import { userSession } from './user-session';
import env from '@/services/env';

const abTest = cloneDeep(gdAbTest);


const isDev = env.env === 'fat'

const device_id = getUserDeviceId();


/**
 * 获取初始化 app 信息
 * @param {Boolean} isDev 是否是测试或开发环境
 */
function getInitAppConfig(isDev) {
    // return isDev ? {
    //     app_key: 'ABTEST_5efd644b-019b-426c-8ade-805e77d7aa52',
    //     app_name: 'gd_web_dev',
    //     user_id: userSession.userId || '',
    //     device_id,
    //     is_login: userSession.isLogin ? '1' : '0'
    // } : {
    //     app_key: 'ABTEST_f6519045-8bfd-4f0d-b690-8ab5b019bf8c',
    //     app_name: 'gd_web',
    //     user_id: userSession.userId || '',
    //     device_id,
    //     is_login: userSession.isLogin ? '1' : '0'
    // };


    return isDev ? {
        // https://data.gaoding.com/index.html#/abtest/expItem?appId=24&expId=2757
        app_key: "ABTEST_762702f3-d6c1-4852-9b1c-96e057d9689a",
        app_name: "gd_gdesign_web_dev",
        device_id,
        user_id: userSession.userId || '',
        is_login: userSession.isLogin ? '1' : '0'
    } : {
            app_key: "ABTEST_8b48f2d8-e171-44b2-83f5-47a08c9adc84",
            app_name: "gd_gdesign_web",
            device_id,
            user_id: userSession.userId || '',
            is_login: userSession.isLogin ? '1' : '0'
        };
}

/**
 * 初始化
 */
let inited = false;
async function init() {
    if (inited) return;
    try {
        if (userSession.isLogin) {
            await userSession.getUser();
        }
    }
    finally {
        abTest.init(getInitAppConfig(isDev));
        // 初始化AB策略信息，并缓存到本地
        abTest.getExps && abTest.getExps();
        inited = true;
    }
}

/**
 * 更新 userID
 * @param {Number} userId 用户 id
 */
function setUserId(userId) {
    return abTest.setUserId(userId);
}
/**
 * 更新 dividceId
 * @param {Number} userId 用户 id
 */
function setDeviceId(dividceId) {
    return abTest.setDeviceId(dividceId);
}

const abValueCache = {}; // 需要缓存的 AB 值
/**
 * 根据变量名称获取所匹配的版本信息的变量值
 * @param {String} abKey 变量名
 * @param {String} defaultValue 当没有获取到匹配值的时候返回的默认值
 * @param {Object} opts useCache 是否缓存 value
 */
async function getValue(abKey, defaultValue, opts = {}) {
    await init();
    const { useCache } = opts;
    // 不需要缓存则直接发起请求获取
    if (!useCache) {
        return abTest.getValue(abKey, defaultValue);
    }

    if (isNil(abValueCache[abKey])) {
        abValueCache[abKey] = abTest.getValue(abKey, defaultValue);
    }
    return Promise.resolve(abValueCache[abKey]);
}

/**
 * 清空 ab 策略缓存
 */
function clearExps() {
    abTest._clearMatchedExps();
}

init();

/**
 * 重新获取 ab 策略
 */
userSession.on('login', () => {
    clearExps();
    init();
});

/**
 * 清空 ab 策略
 */
event.add(event.eventNames.logout, () => {
    clearExps();
});

export const abtest = abTest;

export default {
    setUserId,
    setDeviceId,
    getValue
};
