<template>
  <div class="gaoding-uupoop">
    <div class="gaoding-uupoop-header-wrap">
         <Header />
    </div>
    <div class="gaoding-uupoop-container-wrap">
        <div class="gaoding-uupoop-left-wrap">
            <Menu/>
        </div>
    <div class="gaoding-uupoop-right-wrap">
        <div class="gaoding-uupoop-right-main">
            <PsProfessional />
            <PsSimplify />
            <MattingImage />
            <Material />
            <PSDraw />
            <Banner />
        </div>
        <div class="gaoding-uupoop-right-footer">
             <Footer/>
        </div>
    </div>
    <DownloadGuideBar v-if="isMobile" :type="3"/>
  </div>
  </div>
</template>
<script>
import Header from  "./gaoding-uupoop/header"
import Menu from "./gaoding-uupoop/menu.vue"
import PsProfessional from './gaoding-uupoop/ps-professional';
import PsSimplify from './gaoding-uupoop/ps-simplify';
import MattingImage from './gaoding-uupoop/matting-image';
import Material from './gaoding-uupoop/material';
import PSDraw from './gaoding-uupoop/ps-draw';
import Footer from './gaoding-uupoop/footer';
import Banner from './gaoding-uupoop/banner';
import DownloadGuideBar from './gaoding-uupoop/download-guide-bar/index.vue';
import { platform } from '@gdesign/platform';

export default({
    components:{
        Header,
        PsProfessional,
        PsSimplify,
        MattingImage,
        Material,
        PSDraw,
        Menu,
        Footer,
        DownloadGuideBar,
        Banner,
    },
    computed: {
        isMobile: () => platform.isMobile
    }
})
</script>
<style lang='less'>
body {
    // @TODO 发到线上后 其他页面的 css 有个全局的css  body overflow: hidden; 覆盖,所以这里我们按照线上的场景去 mock
    overflow: hidden;
}
.gaoding-uupoop {
    overflow-y: scroll;
    height: 100vh;
    &-header-wrap {
        top: 0;
        width: 100%;
        position: sticky;
        height: 57px;
        z-index: 999;
   }
    &-container-wrap {
        display: flex;
    }

   &-left-wrap {
        height: 100%;
        width: 232px;
        min-width: 232px;
        height: calc(100vh - 57px) !important;
        top: 57px;
        position: sticky;

   }
}

.gaoding-uupoop-right-wrap {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        overflow-x: hidden;
        .gaoding-uupoop-right-main {
            margin: auto;
            padding: 0 24px;
            width: 100%;
        }
        .gaoding-uupoop-right-footer{
            width: 100%;
            display: flex;
            justify-content: center;
        }
}
</style>
