<template>
    <svg
        role="img"
        aria-label="id-photo"
        class="gd_design_icon gd_design_icon-id-photo"
        focusable="false"
        data-icon="id-photo"
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.9995 20.5012C14.2223 20.5012 16.2457 19.648 17.7603 18.2513C16.2446 16.853 14.2217 16.0012 11.9992 16.0012C9.77682 16.0012 7.75409 16.8528 6.23835 18.2509C7.75298 19.6479 9.7766 20.5012 11.9995 20.5012ZM11.9992 14.5012C14.6107 14.5012 16.9885 15.5023 18.7696 17.1416C19.8551 15.7141 20.4995 13.9329 20.4995 12.0012C20.4995 7.30674 16.6939 3.50116 11.9995 3.50116C7.30509 3.50116 3.49951 7.30674 3.49951 12.0012C3.49951 13.9327 4.1438 15.7138 5.22918 17.1412C7.01024 15.5021 9.38781 14.5012 11.9992 14.5012ZM21.9995 12.0012C21.9995 14.3661 21.1785 16.5393 19.806 18.2514L19.8062 18.2516C17.9735 20.5379 15.1573 22.0019 11.9992 22.0019C8.841 22.0019 6.02478 20.5379 4.19214 18.2516L4.19263 18.2509C2.82034 16.539 1.99951 14.3659 1.99951 12.0012C1.99951 6.47831 6.47666 2.00116 11.9995 2.00116C17.5224 2.00116 21.9995 6.47831 21.9995 12.0012ZM14 9.50195C14 10.6065 13.1046 11.502 12 11.502C10.8954 11.502 10 10.6065 10 9.50195C10 8.39738 10.8954 7.50195 12 7.50195C13.1046 7.50195 14 8.39738 14 9.50195ZM15.5 9.50195C15.5 11.4349 13.933 13.002 12 13.002C10.067 13.002 8.5 11.4349 8.5 9.50195C8.5 7.56896 10.067 6.00195 12 6.00195C13.933 6.00195 15.5 7.56896 15.5 9.50195Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
import './index.less';

export default {
    name: 'IconIdPhoto',
};
</script>
