/**
 * services/load-image
 */

import Promise from 'bluebird';

export default function loadImage(url = '') {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.crossOrigin = '';

        img.onload = () => {
            resolve(img);
        };

        img.onerror = () => {
            reject(new Error('Image load error: ' + url));
        };

        img.src = url;
    });
};

export function loadImageByFile(file) {
    return (new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = () => {
            reject(new Error('File load error'));
        };

        reader.readAsDataURL(file);
    }))
        .then((url) => {
            return loadImage(url);
        });
}

export function svgToImageBlob(svg, width = 400, height = 400) {
    return loadImage(svg)
        .then(image => {
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            context.fillStyle = 'rgba(0, 0, 0, 0)';
            context.fillRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);
            return new Promise((resolve) => {
                canvas.toBlob(resolve, 'png');
            });
        });
};
