import { OAuth2Client } from '@gaoding/oauth2-client';
import env from './env';

let client = null;
export function getOauth2Client() {
  if (!client) {
    client = new OAuth2Client({
      env: env.env,
      clientId: 'gaodingx',
      clientSecret: '7da458070e57b98e11d00d9286f23537',
      // authorizationEndpoint: `${env.appUmsUrl}/cgi-bin`,
      // redirectURI: `${env.gaodingUrl}/api/ums/redirect`
    });
  }
  return client;
}
