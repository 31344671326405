import axios from './axios';



// 获取用户信息
export function getUser() {
    return axios.get(`/api/users/me`);
}


export const getToken = (data = {}) => {
    return axios.post(`/api/tokens`, { ...data })
}

export const getAppVErsion = () => {
    return axios.get('/api/app/release')
}

export const getFriendSites = () => {
    return axios.get('/api/friend-sites')
}

export const getFeaturePages = () => {
    return axios.get('/api/feature-pages')
}

export const getBanners = () => {
    return axios.get('/api/v3/oc/v2/delivery-pits/uupoop_bg_img_banner/modules', {
        headers: {
            'X-Business-Id': 13,
            'X-Channel-Id': 32
        }
    })
}
