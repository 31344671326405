import Router from 'vue-router';
import Vue from 'vue';
import Root from './components/root';
import PSLayout from './components/ps-layout';

import ABPage from './components/ab-page.vue';

Vue.use(Router);

const routerInstance = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/v1',
      component: Root,
      children: [
        {
          path: '',
          name: 'home-page',
          component: () => import('./components/home-page'),
        },
        {
          path: 'old',
          name: 'old-page',
          component: () => import('./components/old-page'),
        },
      ],
    },
    {
      path: '/editor',
      component: PSLayout,
      children: [
        {
          path: '',
          name: 'editor-page',
          component: () => import('./components/editor-page'),
        },
      ]
    },
    {
      path: '/',
      name: 'ABPage',
      component: ABPage,
      meta: {
          tdk: {
            title: '【在线PS软件】在线PS图片（照片）处理工具_在线制作编辑图片在线PS精简版',
            description: 'PS在线图片编辑器是一个专业精简的在线ps图片照片制作处理软件工具，绿色免安装，免下载，直接在浏览器打开就可用它修正，调整和美化图像。',
            keywords: 'PS,ps软件,在线ps,图片处理软件'
          },
          footerType: 'uupoop'
      }
    }
  ],
});
export default routerInstance;

