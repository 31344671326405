import { createWindTrack } from '@gaodingx/shared/lib/lib/tracker';

export const buttonTracker = createWindTrack({
    /**
     * 资源曝光
     * 文档地址：https://doc.huanleguang.com/pages/viewpage.action?pageId=181589781
     *
     */
    expose(data: any) {
        return {
            event_id: 39024,
            event: 'button_expose',
            project_name: 'gd_web',
            browser_language: window.navigator.language,
            ...data
        };
    },
    /**
     * 按钮点击上报
     * 文档地址：https://doc.huanleguang.com/pages/viewpage.action?pageId=74530857
     *
     * @returns
     */
    click(data: any) {
        return {
            event_id: 39008,
            project_name: 'gd_web',
            event: 'button_click',
            ...data
        };
    }
});
