<template>
   <GaodingUupoop />
</template>
<script>

import GaodingUupoop from "./gaoding-uupoop";
export default {
    components: {
        GaodingUupoop,
    },
    data() {
        return {

        };
    },

};
</script>
