/* eslint-disable getter-return */
import Vue from "vue";
import { isObject } from "lodash";
import { AuthDialog, guestUser, config, LeftContent, createOAuth2Client } from "@gaoding/auth";
import { Message } from "@gaoding/gdui";
import Event from "events";
import { event } from "./event";
import env  from "./env";
import { getUser } from "../apis";


config.env = env.env;
const oauth2Client = createOAuth2Client();

// config.GAODING_URL = env.gaodingUrl;
// config.UMS_URL = env.appUmsUrl;
// 本地开发需自己配置相关属性
// if (process.env.NODE_ENV === "development") {
//     config.redirectURI = "http://www.dev.gaoding.com/api/ums/redirect";
//     config.gaodingURL = "http://www.dev.gaoding.com";
// }

function toCamel(str) {
    return str.replace(
        /([^_])(?:_+([^_]))/g,
        ($0, $1, $2) => $1 + $2.toUpperCase()
    );
}

const GUEST_LOGIN = 'guest.login';
const USER_LOGIN = 'user.login';
const LOGIN = 'login';
const USER_CHANGE = 'user.change';
const LOGOUT = 'logout';
const REGISTER = 'register';
export const Events = {
    GUEST_LOGIN,
    USER_LOGIN,
    USER_CHANGE,
    LOGOUT,
    LOGIN,
    REGISTER,
};

const defaultConfig = {
    showClose: true,
    tab: "wechat",
    maskClosable: true,
    width: "749px",
    slotContent: LeftContent,
};
class UserSession extends Event {
    static Events = Events;
    user = null;

    async init() {
        try {
            const res = await getUser();
            if (res && res.data) {
                this.setUser(res.data);
            }
        } catch (err) {
            await this.loginGuest();
        }
    }

    setUser(user) {
        const oldUser = this.user;

        // 主站的业务组件需要用驼峰的方式获取user内字段
        const fn = (obj) =>
            obj &&
            Object.keys(obj).forEach((key) => {
                const value = obj[key];
                key = toCamel(key);
                obj[key] = value;
                if (isObject(value)) {
                    fn(value);
                }
            });

        fn(user);
        this.user = user;

        this.emit(event.eventNames.CAHNGE_USER, this.user, oldUser);
        event.invoke(event.eventNames.CAHNGE_USER, this.user, oldUser);
    }

    get isLogin() {
        return this.user && this.user.type !== 0;
    }

    get userId() {
        if (this.user) {
            return this.user.id;
        }
        return 0;
    }

    get isVIP() {
        return (
            this.isLogin &&
            this.user.vip &&
            this.user.vip.level > 0 &&
            !this.user.vip.is_expired
        );
    }

    get VIP() {
        // 1 旧版标准VIP、80 个人VIP、100 新版标准VIP
        const level = [1, 80, 100];
        if (
            this.isVIP &&
            level.includes(this.user.vip.level) &&
            !this.user.vip.is_expired
        ) {
            return this.user.vip;
        }
    }

    get SVIP() {
        // 2 旧版尊享VIP、200 新版尊享VIP
        const level = [2, 200];
        if (
            this.isVIP &&
            level.includes(this.user.vip.level) &&
            !this.user.vip.is_expired
        ) {
            return this.user.vip;
        }
    }

    // 老vip用户
    get oldVIP() {
        return this.VIP && this.user.vip.vipId === 1;
    }

    // 老svip用户
    get oldSVIP() {
        return this.SVIP && this.user.vip.vipId === 2;
    }

    get PVIP() {
        if (this.isVIP && this.user.vip.level === 80) {
            return this.user.vip;
        }
    }

    get isAutoPay() {
        if (this.isVIP && (this.VIP || this.SVIP)) {
            return this.user.vip.isAutoPay;
        }
    }

    get hasTeam() {
        return this.isLogin && !!this.user.teams && !!this.user.teams.length;
    }

    get vipType() {
        if (this.PVIP) {
            return "pvip";
        }
        if (this.SVIP) {
            return "svip";
        }
        if (this.VIP) {
            return "vip";
        }

        return "";
    }

    get deviceId() {
        return guestUser.guestfinger;
    }

    isGuestUser(user) {
        return user && user.type === 0;
    }

    isGuest() {
        return this.isGuestUser(this.user);
    }

    async loginGuest() {
        const res = await guestUser.login();
        this.setUser(res);
        event.emit(event.eventNames.guestLogin, res);
        return this.user;
    }

    async getUser() {
        if (this.user) return this.user;
        return this.loginGuest();
    }

    // 是否打开登录弹窗
    __openModal = false;

    async login(config) {
        this.emit("hello");
        if (this.user) {
            console.warn("已在登录状态");
        }

        if (this.__openModal) return;
        this.__openModal = true;
        event.invoke("login.expose");

        const modalConfig = {...defaultConfig};

        if (typeof config === "object") {
            Object.assign(modalConfig, defaultConfig, config);
        } else {
            modalConfig.tab = config || "wechat";
        }

        // 注册到套版用户的灰度标志，目前套版用户已经全量了，所以直接写死就行
        modalConfig.canaryTag = 'string_1';

        // let devId = guestUser.guestfinger;
        const isNewLogin = false;

        const isOk = await AuthDialog.open({
            ...modalConfig,
            onCancel() {
                event.invoke("login.close", isNewLogin);
            },
            on: {
                "update.type": (type, oldType) => {
                    const data = {
                        type,
                        oldType,
                        loginVersion: isNewLogin,
                    };
                    event.invoke("login.type", data);
                },
                success: (e) => {
                    if (e.action === "login") {
                        Message.success("登录成功");
                        const user = e.data;
                        if (!user) {
                            const err = new Error();
                            err.status = 401;
                            throw err;
                        }
                        this.setUser(user);
                        this.emit("login", e);
                        location.reload();
                    }
                    e.login_version = isNewLogin;
                    event.invoke(event.eventNames.appSuccess(e.action), e);
                },
                error(e) {
                    Message.error(e.error);
                    e.login_version = isNewLogin;
                    event.invoke(event.eventNames.appError(e.action), e);
                },
            },
        });
        this.__openModal = false;
        return isOk;
    }

    async logout() {
        await event.asyncInvokeAll(event.eventNames.logout);
        oauth2Client.logout();
        location.href = `${env.gaodingUrl}/logout?redirect_url=${location.protocol}/${location.host}`;
    }
    toBusiness(options) {
        if (!window) return;
        const { redirectURL = '', trackerEntrance = '' } = options || {};
        const jumpURL = `http://gaoding.com/redirect-qiye?redirectURL=${redirectURL}&trackerEntrance=${trackerEntrance}`;
        window.open(jumpURL, '_blank');
    }
}
export const userSession = Vue.observable(new UserSession());
export default UserSession
