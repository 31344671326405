import axios from "axios";
import { getOauth2Client } from "../services/oauth2-client";
import { set } from "lodash";
import to from 'await-to-js'
import env from '../services/env'

const instance = axios.create({
    baseURL: env.gaodingUrl,
    withCredentials: true,
})
instance.interceptors.request.use(async (config) => {
    const client = getOauth2Client();
    const [err,authorization] = await to(client.getAuthorizationHeader());
    if (err) {
        console.log('err', err)
    }
    if (authorization) {
        set(config, "headers.Authorization", authorization);
    }
    return config;
});

export default instance;
