
export const createQuery = (obj) => {
    let queryArr = [];
    for (const key in obj) {
        if (![null, undefined].includes(obj[key])) {
            queryArr.push(`${key}=${obj[key]}`)
        }
    }
    return queryArr.join('&')
}

export const linkTo = (url, type = '_self') => {
    window.open(url, type)
}
