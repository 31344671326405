<template>
    <svg
        role="img"
        aria-label="template"
        class="gd_design_icon gd_design_icon-template"
        focusable="false"
        data-icon="template"
        aria-hidden="true"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 4.5C4.72386 4.5 4.5 4.72386 4.5 5V19C4.5 19.2761 4.72386 19.5 5 19.5H14.2C14.4761 19.5 14.7 19.2761 14.7 19V5C14.7 4.72386 14.4761 4.5 14.2 4.5H5ZM3 5C3 3.89543 3.89543 3 5 3H14.2C15.3046 3 16.2 3.89543 16.2 5V19C16.2 19.1726 16.1781 19.3401 16.137 19.4998C16.1781 19.3401 16.2 19.1726 16.2 19V18.5863L18.5694 19.0041C18.8413 19.052 19.1007 18.8704 19.1486 18.5985L20.9255 8.52153C20.9734 8.24958 20.7918 7.99025 20.5199 7.9423L16.2 7.18059V5.65745L20.7804 6.46509C21.8681 6.65689 22.5945 7.69421 22.4027 8.782L20.6258 18.8589C20.434 19.9467 19.3967 20.6731 18.3089 20.4813L15.8986 20.0563C15.8986 20.0563 15.8986 20.0563 15.8987 20.0562C15.5456 20.6229 14.9168 21 14.2 21H5C3.89543 21 3 20.1046 3 19V5Z"
            fill="currentColor"
        />
    </svg>
</template>

<script>
import './index.less';

export default {
    name: 'IconTemplate',
};
</script>
