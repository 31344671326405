<template>
    <footer class="gaoding-uupoop-footer-wrap">
        <div>
            <div class="gaoding-uupoop-footer-links">
                <ul>
                    <li v-for="item in footerLinks" :key="item.title">
                        <a target="_blank" :href='item.href' :rel="item.rel">{{ item.title }}</a>
                    </li>
                </ul>
            </div>
            <div class="gaoding-uupoop-site-number-info">
                <a :href="caseNumber.href">{{ caseNumber.title }}</a>
                <span> | </span>
                <div>{{ serviceCompany.title }}</div>
                <span> | </span>
                <a :href="netNumber.href">{{ netNumber.title }}</a>
                <span> | </span>
                <div>{{ version.title }}</div>
                <span> | </span>
                <a :href="doc.href">{{ doc.title }}</a>
            </div>
        </div>
    </footer>
</template>

<script>
import { footerLinks } from './constant';
import { IconPhoneFill, IconComputerFill, IconAppletFill } from '../../icons';
import { linkTo } from  "../../utils/query"
import { GAODING_UUPOOP_ENV } from "../../services/env"

const caseNumber = {
    title: '闽ICP备18003982号-4',
    href: 'https://beian.miit.gov.cn'
};
const serviceCompany = {
    title: `© 2017 - ${new Date().getFullYear()} 稿定（厦门）科技有限公司`
};
const netNumber = {
    title: '互联网安全备案号:35020602001758',
    href: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020602001758'
};
const version = {
    title: '版本号:iOS:5.1.1Android:5.1.1'
};
const doc = {
    title: '用户协议&隐私协议',
    href: 'https://www.gaoding.com/article/20526'
};

export default {
    components: {
        IconPhoneFill,
        IconComputerFill,
        IconAppletFill
    },
    data() {
        return {
            footerLinks,
            caseNumber,
            serviceCompany,
            netNumber,
            version,
            doc,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl
        }
    },
    methods:{
        linkTo(){
            linkTo(`${this.gaodingUrl}/download?channelCode=web_footer`)
        }
    }
}
</script>

<style lang="less">
.gaoding-uupoop-footer-wrap {
    @layout-width: 1200px;
    @scale: 0.95;

    width: @layout-width;
    @media screen and (max-width: 1440px) {
        width: @layout-width * @scale;
    }
    .gaoding-uupoop-footer-links {
        width :1015px;
        margin: auto;
        padding-top: 20px;
        ul {
            padding: 0 12px;
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            li {
                padding: 0;
                margin: 0;
                font-size: 12px;
                display: inline;
                list-style: none;

                a {
                    cursor: pointer;
                    color: currentcolor;
                    &:hover {
                        color: #2254f4 !important;
                    }
                }
            }
        }
    }
    .gaoding-uupoop-site-number-info {
        padding-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        justify-content: center;
        color: #9da3ac;
        a {
            font-size: 12px;
            cursor: pointer;
            text-decoration: none;
            color: #9da3ac;
            &:hover {
                color: #2254f4;
            }
        }
        span {
            color: #9da3ac;
            font-size: 12px;
            margin: 0 8px;
        }
        div {
            display: inline-block;
        }
    }
}

</style>
