<template>
    <svg
        role="img"
        aria-label="close-small"
        class="gdesign-icon gdesign-icon-close-small"
        focusable="false"
        data-icon="close-small"
        aria-hidden="true"
        v-on="$listeners"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 13.0548L16.4452 17.5L17.5 16.4452L13.0548 12L17.5 7.55484L16.4452 6.5L12 10.9452L7.55484 6.5L6.5 7.55484L10.9452 12L6.5 16.4452L7.55484 17.5L12 13.0548Z"
            fill="currentColor"
        />
    </svg>
</template>
<script>
import './index.less';

export default {
    name: 'IconCloseSmall',
   
};
</script>
