/**
 * services/oss
 */

import axios from 'axios';
import Promise from 'bluebird';
import { escapeRegExp } from 'lodash';
// import { Api } from '../services/api';
import { userSession } from './user-session';
// import { env } from '@gaodingx/shared';
import env from './env'
import {getToken} from '../apis'
 
const rAbsURL = /^(\/|https?:\/\/)/i;
const baseURL = env.assetsBaseURL || '/assets/';
const rBaseURL = new RegExp('^(?:https?:)?' + escapeRegExp(baseURL.replace(/^https?:/i, '')));

const urlEncode = encodeURIComponent;

export default {
    // url
    baseURL: baseURL,
    normalizeUrl(key, params) {
        let newURL = String(key)
            .trim()
            .replace(rBaseURL, '');

        // still need params when rAbsURL
        // if(!newURL || rAbsURL.test(newURL)) {
        //     return key;
        // }
        if (!newURL) {
            return key;
        }

        // add prefix if not rAbsURL
        if (!rAbsURL.test(newURL)) {
            newURL = baseURL + newURL;
        }

        // params
        if (params) {
            if (typeof params === 'object') {
                params = Object.keys(params)
                    .map(k => {
                        return urlEncode(k) + '=' + urlEncode(params[k]);
                    })
                    .join('&');
            }

            newURL += newURL.indexOf('?') < 0 ? '?' : '&';
            newURL += params;
        }

        return newURL;
    },

    uid: 0,
    // key
    getKey(file, key = '') {
        // auto prefix
        if (!key) {
            key = [env.assetsPrefix + userSession.userId, 'uupoop'].join('/');

            key = key.replace(/\/*$/, '/');
        }

        // fill filename
        // 如果以 / 结尾，则自动补全文件名
        if (key.slice(-1) === '/') {
            let now = new Date();

            let filename = now.getFullYear();

            filename += ('00' + (now.getMonth() + 1)).slice(-2);
            filename += ('00' + now.getDate()).slice(-2);
            filename += '-';
            filename += ('00' + now.getHours()).slice(-2);
            filename += ('00' + now.getMinutes()).slice(-2);
            filename += ('00' + now.getSeconds()).slice(-2);

            filename += `-${Math.random()
                .toString(16)
                .slice(-4)}`;
            filename += '.' + this.getExtByFile(file);

            key += filename;
        }

        return key;
    },
    extsMap: {
        'svg+xml': 'svg',
        jpeg: 'jpg'
    },
    getExtByFile(file) {
        let name = file.name;
        if (!name) {
            name = file.type.split('/')[1];
        }

        let ext = name.slice(name.lastIndexOf('.') + 1);
        return this.extsMap[ext] || ext || 'unknow';
    },

    // token
    async getToken(key, qrToken, type) {
        const { data } = await getToken({
            type: 'oss',
            file_type: type,
            qrcode_token: qrToken,
            key: key
        });

        return data;
    },

    uploadTimeout: 5 * 60 * 1000,

    // upload
    upload(file, key, options = {}) {
        const { filename, action, data, timeout, headers, withCredentials, onProgress, isCompressed, ossType } = options;

        return Promise.try(() => {
            if (!file || !file.size) {
                throw new Error('file 参数错误');
            }

            // Auto fill key
            key = this.getKey(file, key);

            // 不允许传文件到根目录
            if (!key || key.indexOf('/') < 0) {
                throw new Error('key 参数错误');
            }
        })
            .then(() => {
                if (!options.type) {
                    // 上传未指定类型的时候，获取文件mime类型
                    options.type = file.type.split('/')[0];
                }
                return this.getToken(key, options.qrcode_token, options.type, ossType);
            })
            .then(token => {
                const formData = new FormData();

                if (data) {
                    Object.keys(data).map(k => {
                        formData.append(k, data[k]);
                    });
                }

                formData.append('key', token.key);
                formData.append('policy', token.policy);
                formData.append('Signature', token.signature);
                formData.append('OSSAccessKeyId', token.access_key);
                formData.append('success_action_status', 200);

                // formData.append('Content-Type', file.type);

                let actionUrl = action;
                if (!actionUrl) {
                    const rImage = /\.(?:png|jpe?g|gif|svg)$/i;

                    // 图片上传优先走压缩服务
                    if (rImage.test(token.key) && !isCompressed) {
                        actionUrl = '//image-uploader.dancf.com/';

                        // oss_action 为内部 oss 上传地址，可能有内网加成（华东区域）
                        formData.append('OSSUploadAction', token.oss_action || token.action);
                    } else {
                        actionUrl = token.action;
                    }
                }

                // Append file at end
                formData.append(filename || 'file', file);

                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                const cancelToken = source.token;

                if (options.getCancelToken) {
                    options.getCancelToken(source);
                }

                return axios
                    .post(actionUrl, formData, {
                        headers,
                        withCredentials,
                        timeout: timeout || this.uploadTimeout,
                        cancelToken: cancelToken,
                        transformResponse: res => {
                            return res;
                        },

                        onUploadProgress(e) {
                            e.percent = (100 * e.loaded) / e.total;

                            if (onProgress) {
                                onProgress(e);
                            }
                        }
                    })
                    .then(() => {
                        if (ossType === 'filems') {
                            return {
                                key: token.key,
                                url: 'https:' + token.action + '/' + token.key
                            };
                        }
                        return {
                            key: token.key
                        };
                    });
            })
            .then(ret => {
                // Fill url
                if (!ret.url) {
                    ret.url = this.baseURL + ret.key;
                }
                return ret;
            });
    }
};
