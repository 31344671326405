<template>
    <div
        :class="
            bem('', {
                autoLayoutBottom,
                togglable: inner_type === 3,
            })
        "
    >
        <div :class="bem('main', { visible })">
            <div :class="bem('content')" v-if="inner_type === 1">
                <span>0基础1分钟</span>
                <span>作图神器</span>
            </div>
            <div :class="bem('content-type-2')" v-else-if="inner_type === 2">
                <div>
                    下载稿定APP
                </div>
                <div>
                    <span>免费使用</span>
                    <span>海量模板作图神器</span>
                </div>
            </div>

            <div :class="bem('content-type-3')" v-else-if="inner_type === 3">
                <img
                    src="https://cdn.dancf.com/fe-assets/20240918/56593ae25146223cb567c5a330a49c74.png"
                    width="60"
                    height="60"
                />

                <div>
                    <div>为更好的使用体验，下载稿定APP</div>
                    <span>免费使用</span>
                    <span>在线作图工具</span>
                </div>
                <IconCloseSmall
                    :class="bem('close-icon')"
                    @click.native.prevent.stop="handleToggle"
                ></IconCloseSmall>
            </div>
            
            <Button @click="download" :class="bem('button')" type="primary" size="small">
                下载App
            </Button>
        </div>
    </div>
</template>

<script>
import {Button} from '@gaoding/gd-antd';
import { IconCloseSmall } from '../../../icons';
import { trackerWind } from '@gaoding/gd-tracker';
import { platform } from '@gdesign/platform';
import AiIcon from './components/ai-icon.vue';
import '@gaoding/gd-antd/es/button/style/index.css'
import { useOpenInstall, isReady, openInstall } from '../../../utils/use-open-install'

import { bemMixin } from '@gaoding/gdui/lib/utils';
const APP_DOWNLOAD_URL = 'http://a.app.qq.com/o/simple.jsp?pkgname=com.hlg.daydaytobusiness';
const IOS_APPSTORE_DOWNLOAD_URL =
    'https://apps.apple.com/cn/app/\u7A3F\u5B9A\u8BBE\u8BA1-\u7528\u6A21\u677F\u8F7B\u677E\u4F5C\u56FE\u62FC\u56FE/id1113276760';

useOpenInstall('mz_uupoop');

export default ({
    name: 'download-guide-bar',
    components: {
        Button,
        AiIcon,
        IconCloseSmall,
    },
    mixins: [bemMixin],
    props: {
        // 滚动容器
        scrollContainer: {
            type: Object
        },
        // 展示类型
        type: {
            type: Number,
            default: 1,
        },
        // 是否自动布局到底部
        autoLayoutBottom: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            inner_type: this.type,
        }
    },
    computed: {
        visible() {
            return !platform.isApp;
        }
    },
    watch: {
        visible: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    trackerWind.trackEvent('dialog_expose', {
                        event_id: 39012,
                        module_name: '下载app引导条',
                        subject_name: '弹窗',
                    });
                }
            },
        }
    },
    methods: {
        tryWakeUpApp() {
            if (isReady) {
                openInstall.wakeupOrInstall?.();
              
            } else {
                try {
                    location.href = 'ttxs://home';
                } catch (e) {
                        console.log('error: ', e);
                }
            }
            // 唤起失败根据平台跳转下载页
            setTimeout(() => {
                location.href = platform.isIOS ? IOS_APPSTORE_DOWNLOAD_URL : APP_DOWNLOAD_URL;
            }, 1000);
        },
        handleToggle() {
            this.inner_type = 2;
        },
        download() {
            trackerWind.trackEvent('button_click', {
                event_id: 39008,
                module_name: '下载app引导条',
                subject_name: '按钮',
                button_name: '下载App',
            });
            // 唤起app
            this.tryWakeUpApp();
        }
    }
});
</script>

<style lang="less" scoped>


.download-guide-bar {
    &__content {
        font: var(--text-h6-bold);

        & > span {
            &:nth-child(1) {
                color: var(--text-color-emphasis, #2254f4);
            }

            &:nth-child(2) {
                color: var(--text-color-primary, #222529);
            }
        }

        &-type-2 {
            color: var(--text-color-primary, #222529);
            /* text-p1/bold */
            font-family: var(--font-family-body, "pingfang SC");
            font-size: var(--font-size-100, 15px);
            font-style: normal;
            font-weight: var(--font-weight-bold, 600);
            line-height: 150%; /* 22.5px */

            span {
                &:nth-child(1) {
                    color:  var(--text-color-emphasis, #2254f4);
                }
            }

        }

        &-type-3 {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-300, 12px);
            align-items: center;

            div {
                text-align: center;

                span:first-of-type {
                    color: var(--text-color-emphasis, #2254f4);
                }
            }
        }
    }

    &__main {
        display: none;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        height: calc(72px + env(safe-area-inset-bottom));
        padding-right: 24px;
        padding-bottom: env(safe-area-inset-bottom);
        padding-left: 24px;
        background: #fffffff5;
        border-top: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: var(--size-0, 0);
        backdrop-filter: blur(10px);
        background: linear-gradient(180deg, rgba(34, 84, 244, 0.16) 0%, rgba(34, 84, 244, 0.00) 35%), rgba(255, 255, 255, 0.95);
        box-shadow: 0px -1px 0px 0px #FFF;

        &--visible {
            display: flex;
        }
    }

    &--togglable {
        .download-guide-bar__main {
            display: flex;
            flex-direction: column;
            gap: var(--spacing-600, 24px);
            align-items: center;
            justify-content: center;
            height: calc(234px + env(safe-area-inset-bottom));
            background: linear-gradient(
                    180deg,
                    rgba(34, 84, 244, 0.16) 0%,
                    rgba(34, 84, 244, 0) 35%
                ),
                rgba(255, 255, 255, 0.95);
            border-radius: 16px 16px var(--size-0, 0) var(--size-0, 0);
            box-shadow: 0 -1px 0 0 #fff;
            backdrop-filter: blur(10px);
            .download-guide-bar__button {
                width: 100%;
            }
        }
    }

    &__button {
        width: 135px;
        height: 40px;
        border-radius: var(--button-border-radius-small, 8px);
        border: 1px solid var(--button-border-color-primary-regular, rgba(0, 0, 0, 0.00));
        background: var(--button-background-color-primary-regular, #2254F4);
    }

    &__close-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        color: #7f8792;
    }
}
.download-guide-bar--autoLayoutBottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    // left: var(--layout-silde-width); // 左侧导航的宽度。若改为动态展示，此处需修改
    z-index: 100; // 层级高于页面内容，低于所有界面弹窗
    overflow: hidden;
}
</style>
