var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gaoding-uupoop-material"},[_vm._m(0),_c('div',{staticClass:"templet-content"},[_c('TrackButton',{attrs:{"trackData":{
            page_name:'UUPOOP页',
            module_name:'抠图及一站式工具',
            button_name: _vm.templetMap.image.title
        }}},[_c('div',{staticClass:"templet-content-item",style:({ 'background-image': ("url(" + (_vm.templetMap.image.backgroundImage) + ")") }),on:{"click":function($event){return _vm.linkTo(_vm.templetMap.image.path)}}},[_c('div',{staticClass:"templet-content-item-header"},[_c('div',{staticClass:"templet-content-item-header-title"},[_vm._v(" "+_vm._s(_vm.templetMap.image.title)+" ")]),_c('div',{staticClass:"templet-content-item-header-icon"},[_c('IconArrowForward')],1)])])]),_c('TrackButton',{attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: _vm.templetMap.h5.title
        }}},[_c('div',{staticClass:"templet-content-item",style:({ 'background-image': ("url(" + (_vm.templetMap.h5.backgroundImage) + ")") }),on:{"click":function($event){return _vm.linkTo(_vm.templetMap.h5.path)}}},[_c('div',{staticClass:"templet-content-item-header"},[_c('div',{staticClass:"templet-content-item-header-title"},[_vm._v(" "+_vm._s(_vm.templetMap.h5.title)+" ")]),_c('div',{staticClass:"templet-content-item-header-icon"},[_c('IconArrowForward')],1)])])]),_c('div',{staticClass:"templet-content-item-wrap"},[_c('TrackButton',{attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: _vm.templetMap.ppt.title
            }}},[_c('div',{class:['templet-content-item', 'templet-content-item-small'],style:({ 'background-image': ("url(" + (_vm.templetMap.ppt.backgroundImage) + ")") }),on:{"click":function($event){return _vm.linkTo(_vm.templetMap.ppt.path)}}},[_c('div',{staticClass:"templet-content-item-header"},[_c('div',{staticClass:"templet-content-item-header-title"},[_vm._v(" "+_vm._s(_vm.templetMap.ppt.title)+" ")]),_c('div',{staticClass:"templet-content-item-header-icon"},[_c('IconArrowForward')],1)])])]),_c('TrackButton',{attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: _vm.templetMap.video.title
            }}},[_c('div',{class:['templet-content-item', 'templet-content-item-small'],style:({ 'background-image': ("url(" + (_vm.templetMap.video.backgroundImage) + ")") }),on:{"click":function($event){return _vm.linkTo(_vm.templetMap.video.path)}}},[_c('div',{staticClass:"templet-content-item-header"},[_c('div',{staticClass:"templet-content-item-header-title"},[_vm._v(" "+_vm._s(_vm.templetMap.video.title)+" ")]),_c('div',{staticClass:"templet-content-item-header-icon"},[_c('IconArrowForward')],1)])])])],1)],1),_c('div',{staticClass:"divide"}),_vm._m(1),_c('div',{staticClass:"ai-content"},_vm._l((_vm.AiList),function(item){return _c('TrackButton',{key:item.id,attrs:{"trackData":{
                page_name:'UUPOOP页',
                module_name:'抠图及一站式工具',
                button_name: item.title
            }}},[_c('div',{staticClass:"ai-content-item",style:({
                'background-color': item.backgroundColor,
            }),on:{"click":function($event){return _vm.linkTo(item.path)}}},[_c('div',{staticClass:"ai-content-item-header"},[_c('div',{staticClass:"ai-content-item-header-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"ai-content-item-header-icon"},[_c('IconArrowForward')],1)]),_c('div',{staticClass:"ai-content-item-content"},[_vm._v(" "+_vm._s(item.desc)+" ")]),_c('img',{attrs:{"src":item.image,"alt":item.title}})])])}),1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("海量素材，无限精彩")]),_c('p',[_vm._v("一站式全场景素材供应人人皆可创作")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("稿定Al，一键灵感到创作")]),_c('p',[_vm._v("让设计更简单，无论身在何处")])])}]

export { render, staticRenderFns }